import i18n from "i18n";
import { HAIRLIE_APP_ID, HOGUGU_APP_ID, NAILIE_APP_ID, TANOMEL_APP_ID } from "./common";

const privateCouponCondition = {
  min: {
    key: "min",
    name: i18n.t("content.campaignManagement.coupon.rules.minimumAmount")
  },
  quantumIssue: {
    key: "quantumIssue",
    name: i18n.t("content.campaignManagement.coupon.rules.totalIssuableCount")
  },
  issueCouponNumber: {
    key: "issueCouponNumber",
    name: i18n.t("content.campaignManagement.coupon.rules.issueCouponNumber")
  },
  availableDays: {
    key: "availableDays",
    name: i18n.t("content.campaignManagement.coupon.rules.availableDays")
  },
  allowServicerIds: {
    key: "allowServicerIds",
    name: i18n.t("content.campaignManagement.coupon.rules.allowServicerIds")
  },
  allowCustomerIds: {
    key: "allowCustomerIds",
    name: i18n.t("content.campaignManagement.coupon.rules.allowCustomerIds")
  },
  restrictServicerIds: {
    key: "restrictServicerIds",
    name: i18n.t("content.campaignManagement.coupon.rules.restrictServicerIds")
  },
  restrictCustomerIds: {
    key: "restrictCustomerIds",
    name: i18n.t("content.campaignManagement.coupon.rules.restrictCustomerIds")
  },
  maxServiceUsageForIssue: {
    key: "maxServiceUsageForIssue",
    name: i18n.t("content.campaignManagement.coupon.rules.maxServiceUsageForIssue")
  },
  minServiceUsageForIssue: {
    key: "minServiceUsageForIssue",
    name: i18n.t("content.campaignManagement.coupon.rules.minServiceUsageForIssue")
  },
  startIssue: {
    key: "startIssue",
    name: i18n.t("content.campaignManagement.coupon.rules.startIssue")
  },
  endIssued: {
    key: "endIssued",
    name: i18n.t("content.campaignManagement.coupon.rules.endIssued")
  },
  allowMenuIds: {
    key: "allowMenuIds",
    name: i18n.t("content.campaignManagement.coupon.rules.allowMenuIds")
  },
  availableBookingFrom: {
    key: "availableBookingFrom",
    name: i18n.t("content.campaignManagement.coupon.rules.availableBookingFrom")
  },
  availableBookingTo: {
    key: "availableBookingTo",
    name: i18n.t("content.campaignManagement.coupon.rules.availableBookingTo")
  },
  availableBookingStartTimeFrom: {
    key: "availableBookingStartTimeFrom",
    name: i18n.t("content.campaignManagement.coupon.rules.availableBookingStartTimeFrom")
  },
  availableBookingStartTimeTo: {
    key: "availableBookingStartTimeTo",
    name: i18n.t("content.campaignManagement.coupon.rules.availableBookingStartTimeTo")
  },
  ableToIssueMultiple: {
    key: "ableToIssueMultiple",
    name: i18n.t("content.campaignManagement.coupon.rules.ableToIssueMultiple")
  }
};

const addOnCondition = {
  maxDiscountAmount: {
    key: "maxDiscountAmount",
    name: i18n.t("content.campaignManagement.coupon.rules.maxDiscountAmount")
  }
}

export default {
  PATH: {
    TARGET_LIST_SPOOL_SERVICER: "/target-list-spool/servicer",
    USER: "/user"
  },
  FONT_SIZE: "14px",
  PAGE_LIMIT: 20,
  PAGE_CUSTOMER_RECOMMENDATION_LIMIT: 10,
  TOTAL_LIST_LIMIT: 200,
  DEFAULT_VALIDATE_FAIL_MESSAGE: i18n.t("apiResponseMessage.failValidate"),
  USER_STATUS: {
    WAITING_APPROVAL: 0,
    APPROVED: 1,
    OTHER: 99
  },
  TARGET_LIST_SELECT_TYPE: [
    i18n.t("content.targetListSpool.selectTargetListFromView"),
    i18n.t("content.targetListSpool.selectTargetListFromProcedure")
  ],
  USER_STATUS_TEXT: {
    0: "Waiting for approval"
  },
  DEFAULT_NAME_VALUE: "名前なし",
  FORMAT: {
    TARGER_SPOOL_DATETIME: "YYYY-MM-DD 00:00:00",
    DATE_VIEW: "YYYY/MM/DD",
    MONTH_VIEW: "YYYY/MM",
    SINGLE_SEND_MONTH: "YYYY-MM",
    SINGLE_SEND_DATE: "YYYY-MM-DD",
    DATE_FORMAT_SEND_REQUEST: "YYYY-MM-DD 00:00:00",
    DATETIME_FORMAT: "YYYY/MM/DD HH:mm:ss",
    CAMPAIGN_DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
    COUPON_DATETIME_START_FORMAT: "YYYY-MM-DD 00:00:00",
    COUPON_DATETIME_END_FORMAT: "YYYY-MM-DD 23:59:59",
    COUPON_DATE_FORMAT: "YYYY-MM-DD",
    TIME_FORMAT: "HH:mm"
  },
  DATA_TYPE: {
    STRING: "string",
    NUMBER: "number",
    BOOLEAN: "boolean",
    DATETIME: "datetime",
    MONTH: "month",
    DAY: "day",
    DATETIME_START: "datetime-start",
    DATETIME_END: "datetime-end"
  },
  ROLE: {
    CODE: {
      SUPER_ADMIN: "super_admin",
      ADMINISTRATOR: "admin",
      OPERATOR: "operator",
      VIEWER: "viewer"
    }
  },
  PRODUCT: {
    CODE: {
      NAILIE: "nailie",
      HOGUGU: "hogugu"
    }
  },
  LOGIN_STATUS: {
    LOGGED: "loggedin",
    INVITED: "invited",
    NOT_EXISTED: "not_existed",
    DELETED: "deleted",
    PENDING: "pending",
    PRODUCT_NOT_FOUND: "product_not_found"
  },
  LOCALE: {
    JA: "ja",
    EN: "en"
  },
  SENDER_STATUS: {
    INVALID: ["not_existed", "not_verified"]
  },
  SEARCH_TYPE: {
    INPUT: "input",
    DATE_PICKER: "date_picker",
    DATETIME_PICKER: "datetime_picker",
    SELECT: "select"
  },
  NAME: {
    customer_en: "customer",
    customer_jp: "カスタマー",
    servicer_en: "servicer",
    servicer_jp: "サービサー"
  },

  TARGET_LIST_OPTION: {
    en: {
      target_list_views: "Select Target List from Views",
      target_list_procedure: "Select Target List from Store Procedure"
    },
    ja: {
      target_list_views: "ビューからのターゲットリストを選択する",
      target_list_procedure: "ストアドプロシージャからのターゲットリストを選択する"
    }
  },

  COUPON_TYPE: {
    public: i18n.t("content.campaignManagement.coupon.type.public"),
    private: i18n.t("content.campaignManagement.coupon.type.private")
  },
  COUPON_TYPE_VALUES: {
    public: "Public",
    private: "Private",
    invitation: "Invitation",
    incentive: "Incentive"
  },
  COUPON_TYPE_KEY: "coupon_type",
  PUBLIC_COUPON_CONDITION: {
    min: {
      key: "min",
      name: i18n.t("content.campaignManagement.coupon.rules.minimumAmount")
    },
    quantum: {
      key: "quantum",
      name: i18n.t("content.campaignManagement.coupon.rules.total_count")
    },
    limitPerUser: {
      key: "limitPerUser",
      name: i18n.t("content.campaignManagement.coupon.rules.limitPerUser")
    },
    allowServicerIds: {
      key: "allowServicerIds",
      name: i18n.t("content.campaignManagement.coupon.rules.allowServicerIds")
    },
    allowCustomerIds: {
      key: "allowCustomerIds",
      name: i18n.t("content.campaignManagement.coupon.rules.allowCustomerIds")
    },
    restrictServicerIds: {
      key: "restrictServicerIds",
      name: i18n.t("content.campaignManagement.coupon.rules.restrictServicerIds")
    },
    restrictCustomerIds: {
      key: "restrictCustomerIds",
      name: i18n.t("content.campaignManagement.coupon.rules.restrictCustomerIds")
    },
    maxServiceUsage: {
      key: "maxServiceUsage",
      name: i18n.t("content.campaignManagement.coupon.rules.maxServiceUsage")
    },
    minServiceUsage: {
      key: "minServiceUsage",
      name: i18n.t("content.campaignManagement.coupon.rules.minServiceUsage")
    },
    start: { key: "start", name: i18n.t("content.campaignManagement.coupon.rules.startDate") },
    end: { key: "end", name: i18n.t("content.campaignManagement.coupon.rules.endDate") },
    allowMenuIds: {
      key: "allowMenuIds",
      name: i18n.t("content.campaignManagement.coupon.rules.allowMenuIds")
    },
    availableBookingFrom: {
      key: "availableBookingFrom",
      name: i18n.t("content.campaignManagement.coupon.rules.availableBookingFrom")
    },
    availableBookingTo: {
      key: "availableBookingTo",
      name: i18n.t("content.campaignManagement.coupon.rules.availableBookingTo")
    },
    availableBookingStartTimeFrom: {
      key: "availableBookingStartTimeFrom",
      name: i18n.t("content.campaignManagement.coupon.rules.availableBookingStartTimeFrom")
    },
    availableBookingStartTimeTo: {
      key: "availableBookingStartTimeTo",
      name: i18n.t("content.campaignManagement.coupon.rules.availableBookingStartTimeTo")
    }
  },
  PRIVATE_COUPON_CONDITION: privateCouponCondition,
  INVITATION_COUPON_CONDITION: privateCouponCondition,
  ADD_ON_COUPON_CONDITION: addOnCondition,
  COUPON_SERVICE_APP_ID: {
    Hogugu: HOGUGU_APP_ID,
    Nailie: NAILIE_APP_ID,
    Tanomel: TANOMEL_APP_ID,
    Hairlie: HAIRLIE_APP_ID
  },
  MAX_COUPON_CONDITION: 11,
  MAX_EXTRA_CONDITION: 5,
  SERVICER_DETAIL_URL: {
    nailie: "https://nailie.jp/nailist"
  },
  SENDGRID_UNSUBSCRIBE_GROUP_NAME: process.env.REACT_APP_SENDGRID_UNSUBSCRIBE_GROUP_NAME,
  USER_MENU_SETTING: {
    USER_MANAGER: "userManager",
    TARGET_SPOOL: "targetSpool",
    AI_SUPPORT_TOOL: "aiSupportTool",
    CAMPAIGN: "campaign"
  },
  MENU_ON: "on",
  MENU_OFF: "off",
  COUPON_TYPE_ON: "on",
  COUPON_TYPE_OFF: "off",
  TRANSACTION_STATUS: {
    USED: "USED",
    CANCELED: "CANCELED",
    COMPLETED: "COMPLETED"
  },
  ISSUE_COUPON_STATUS: {
    USED: "USED",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
  },
  INVITATION_COUPON_STATUS: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
  },
  TIME_ZONE_JP: "Asia/Tokyo",
  NUMBER_OF_YEAR: ["00", "01", "02", "03", "04", "05"],
  NUMBER_OF_MONTH: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
  POINT_STATUS_APPROVED: "Approved",
  POINT_STATUS_CANCELLED: "Cancelled",
  POINT_EXPIRY_TYPE_NONE: "Non-exp",
  POINT_EXPIRY_TYPE_ROLLING: "Rolling",
  POINT_EXPIRY_TYPE_FIXED: "Fixed",
  POINT_TYPE_SELECT_LIST: [
    { key: 1, name: i18n.t("content.campaignManagement.point.add") },
    { key: 2, name: i18n.t("content.campaignManagement.point.use") }
  ],
  POINT_EXPIRY_TYPE_SELECT_LIST: [
    i18n.t("content.campaignManagement.point.nonExp"),
    i18n.t("content.campaignManagement.point.rolling"),
    i18n.t("content.campaignManagement.point.fixed")
  ],
  POINT_STATUS_SELECT_TYPE: [
    i18n.t("content.campaignManagement.point.approved"),
    i18n.t("content.campaignManagement.point.cancelled")
  ],
  POINT_TRANSFERABLE_SELECT_LIST: [
    i18n.t("content.campaignManagement.point.notTransferable"),
    i18n.t("content.campaignManagement.point.transferable")
  ],
  IMAGE_SIZE: {
    COUPON_SMALLSIZE_HEIGHT: 30,
    COUPON_SMALLSIZE_WIDTH: 120,
    COUPON_LARGESIZE_HEIGHT: 192,
    COUPON_LARGESIZE_WIDTH: 768,
    ANNOUNCEMENT_SMALLSIZE_HEIGHT: 120,
    ANNOUNCEMENT_SMALLSIZE_WIDTH: 160,
    ANNOUNCEMENT_LARGESIZE_HEIGHT: 768,
    ANNOUNCEMENT_LARGESIZE_WIDTH: 1024
  },
  DEFAULT_COUPON_TYPE_SETTING: {
    public: "on",
    private: "on",
    invitationIncentive: "on"
  },
  TAG_SEARCH_TYPE: {
    contains: "contains"
  },
  TAG_NAME_MAX_LENGTH: 100,
  REGISTER_SERVICE: {
    COUPON: 1,
    POINT: 2
  }
};
