import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from 'i18n';
import { Layout, Affix, Dropdown, Typography, Row, Select } from 'antd';
import {
  CaretDownOutlined,
  SettingOutlined
} from '@ant-design/icons';
import ReactCountryFlag from 'react-country-flag';

import './styles.less';
import helpers from 'utils/helpers';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

function handleChange(input) {
  const { value, isInviteLinkClick } = input;
  helpers.setLocale(value);
  if (isInviteLinkClick) {
    helpers.storeClickInviteLink();
  } else {
    helpers.removeClickInviteLink();
  }
  window.location.reload();
}

const Languages = () => {
  const locales = ['en', 'ja'];
  const { acceptInvite } = useSelector((state) => state.user);
  return (
    <Select defaultValue={helpers.getLocale() || locales[0]} bordered={false}
      onChange={(value => handleChange({ value, isInviteLinkClick: acceptInvite.inviteLinkClicked }))}>
      {
        locales.map(locale =>
          <Select.Option value={(locale)} key={locale}>
            <ReactCountryFlag svg countryCode={helpers.convertCountryCode(locale)} style={{
              fontSize: '1.2em',
              marginBottom: 3
            }} /> {i18n.t(`language.${locale}`)}
          </Select.Option>
        )
      }
    </Select >
  );
};

const Navbar = ({ toggleCollapseMenu, collapseMenu, userLogged, menu, menuSuperAdmin }) => {
  return (
    <Affix offsetTop={0} className='affix-nav'>
      <Header className='header' >
        <div className='header-content'>
          <div className='side-menu-logo'>
            <Link to='/' className='logo-text'>{i18n.t('global.dashBoardTitle')}</Link>
          </div>
          <div className='toggle-collapse-btn'>
            {/* {!collapseMenu ?
              <MenuFoldOutlined onClick={() => toggleCollapseMenu(true)} />
              : <MenuUnfoldOutlined onClick={() => toggleCollapseMenu(false)} />
            } */}
          </div>
          <div>
            <Row>
              <div className='locale-select'>
                {Languages()}
              </div>
              {(menu || menuSuperAdmin) && (<div className='full-flex'>
                <Dropdown trigger='click' overlay={menu} placement='bottomRight'>
                  <div className='user-setting-menu'>
                    <div style={{ maxWidth: 250 }}><Typography.Paragraph ellipsis>{userLogged.fullname}</Typography.Paragraph></div>
                    <CaretDownOutlined className='full-flex' />
                  </div>
                </Dropdown>
                { (userLogged.is_super_admin > 0) ? (
                  <Dropdown trigger='click' overlay={menuSuperAdmin} placement='bottomRight'>
                    <div style={{ height: 64 }}>
                      <SettingOutlined className="anticon-setting-config"/>
                    </div>
                  </Dropdown>
                ) : ''}
              </div>)}
            </Row>
          </div>
        </div>
      </Header>
    </Affix>
  );
};

Navbar.propTypes = {
  toggleCollapseMenu: PropTypes.func,
  collapseMenu: PropTypes.bool,
  logOut: PropTypes.func,
  userLogged: PropTypes.object,
  menu: PropTypes.func,
  menuSuperAdmin: PropTypes.func
};

export default memo(Navbar);
