import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import constants from "utils/constants";
import helpers from "utils/helpers";

const RenderDateFormatJp = (props) => {
  const { value, format } = props;
  const date = helpers.convertUTCtoZone(value, constants.TIME_ZONE_JP);
  return (
    <>
      <span>{!_.isEmpty(value) ? date.format(format) : ""}</span>
    </>
  );
};

RenderDateFormatJp.propTypes = {
  value: PropTypes.string.isRequired,
  format: PropTypes.string
};

RenderDateFormatJp.defaultProps = {
  format: constants.FORMAT.DATETIME_FORMAT
};

export default RenderDateFormatJp;
