import {
  GET_POINT_API_CONFIG_SECRET_KEY_REQUEST,
  GET_POINT_API_CONFIG_SECRET_KEY_SUCCESS,
  GET_POINT_API_CONFIG_SECRET_KEY_FAILED,
  GET_POINT_API_EXPIRY_DURATION_REQUEST,
  GET_POINT_API_EXPIRY_DURATION_SUCCESS,
  GET_POINT_API_EXPIRY_DURATION_FAILED,
  UPDATE_POINT_API_EXPIRY_DURATION_REQUEST,
  UPDATE_POINT_API_EXPIRY_DURATION_SUCCESS,
  UPDATE_POINT_API_EXPIRY_DURATION_FAILED,
  GET_POINT_API_EXCHANGE_RATE_REQUEST,
  GET_POINT_API_EXCHANGE_RATE_SUCCESS,
  GET_POINT_API_EXCHANGE_RATE_FAILED,
  UPDATE_POINT_API_EXCHANGE_RATE_REQUEST,
  UPDATE_POINT_API_EXCHANGE_RATE_SUCCESS,
  UPDATE_POINT_API_EXCHANGE_RATE_FAILED,
  GET_POINT_API_TRANSFER_RATE_REQUEST,
  GET_POINT_API_TRANSFER_RATE_SUCCESS,
  GET_POINT_API_TRANSFER_RATE_FAILED,
  UPDATE_POINT_API_TRANSFER_RATE_REQUEST,
  UPDATE_POINT_API_TRANSFER_RATE_SUCCESS,
  UPDATE_POINT_API_TRANSFER_RATE_FAILED,
  GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST,
  GET_POINT_API_TOTAL_POINT_SEARCH_SUCCESS,
  GET_POINT_API_TOTAL_POINT_SEARCH_FAILED,
  GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST,
  GET_POINT_API_POINT_HISTORY_SEACRH_SUCCESS,
  GET_POINT_API_POINT_HISTORY_SEARCH_FAILED,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_SUCCESS,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_FAILED,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_SUCCESS,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_FAILED,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_SUCCESS,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_FAILED,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_SUCCESS,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_FAILED,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_REPORT_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MOMTHLY_REPORT_SUCCESS,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_REPORT_FAILED,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_SUCCESS,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_FAILED
} from './constants';

export const getPointApiConfigSecretRequest = () => {
  return { type: GET_POINT_API_CONFIG_SECRET_KEY_REQUEST };
};

export const getPointApiConfigSecretSuccess = (payload) => {
  return { type: GET_POINT_API_CONFIG_SECRET_KEY_SUCCESS, payload };
};

export const getPointApiConfigSecretFailed = (payload) => {
  return { type: GET_POINT_API_CONFIG_SECRET_KEY_FAILED, payload };
};

export const getPointApiExpiryDurationRequest = () => {
  return { type: GET_POINT_API_EXPIRY_DURATION_REQUEST};
};

export const getPointApiExpiryDurationSuccess = (payload) => {
  return { type: GET_POINT_API_EXPIRY_DURATION_SUCCESS, payload };
};

export const getPointApiExpiryDurationFailed = (payload) => {
  return { type: GET_POINT_API_EXPIRY_DURATION_FAILED, payload };
};

export const updatePointApiExpiryDurationRequest = (payload) => {
  return { type: UPDATE_POINT_API_EXPIRY_DURATION_REQUEST, payload };
};

export const updatePointApiExpiryDurationSuccess = (payload) => {
  return { type: UPDATE_POINT_API_EXPIRY_DURATION_SUCCESS, payload };
};

export const updatePointApiExpiryDurationFailed = (payload) => {
  return { type: UPDATE_POINT_API_EXPIRY_DURATION_FAILED, payload };
};

export const getPointApiExchangeRateRequest = () => {
  return { type: GET_POINT_API_EXCHANGE_RATE_REQUEST };
};

export const getPointApiExchangeRateSuccess = (payload) => {
  return { type: GET_POINT_API_EXCHANGE_RATE_SUCCESS, payload };
};

export const getPointApiExchangeRateFailed = (payload) => {
  return { type: GET_POINT_API_EXCHANGE_RATE_FAILED, payload };
};

export const updatePointApiExchangeRateRequest = (payload) => {
  return { type: UPDATE_POINT_API_EXCHANGE_RATE_REQUEST, payload };
};

export const updatePointApiExchangeRateSuccess = (payload) => {
  return { type: UPDATE_POINT_API_EXCHANGE_RATE_SUCCESS, payload };
};

export const updatePointApiExchangeRateFailed = (payload) => {
  return { type: UPDATE_POINT_API_EXCHANGE_RATE_FAILED, payload };
};

export const getPointApiTransferRateRequest = () => {
  return { type: GET_POINT_API_TRANSFER_RATE_REQUEST};
};

export const getPointApiTransferRateSuccess = (payload) => {
  return { type: GET_POINT_API_TRANSFER_RATE_SUCCESS, payload };
};

export const getPointApiTransferRateFailed = (payload) => {
  return { type: GET_POINT_API_TRANSFER_RATE_FAILED, payload };
};

export const updatePointApiTransferRateRequest = (payload) => {
  return { type: UPDATE_POINT_API_TRANSFER_RATE_REQUEST, payload };
};

export const updatePointApiTransferRateSuccess = (payload) => {
  return { type: UPDATE_POINT_API_TRANSFER_RATE_SUCCESS, payload };
};

export const updatePointApiTransferRateFailed = (payload) => {
  return { type: UPDATE_POINT_API_TRANSFER_RATE_FAILED, payload };
};

export const getPointApiTotalPointSearchRequest = (payload) => {
  return { type: GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST, payload };
};

export const getPointApiTotalPointSearchSuccess = (payload) => {
  return { type: GET_POINT_API_TOTAL_POINT_SEARCH_SUCCESS, payload };
};

export const getPointApiTotalPointSearchFailed = (payload) => {
  return { type: GET_POINT_API_TOTAL_POINT_SEARCH_FAILED, payload };
};

export const getPointApiPointHistorySearchRequest = (payload) => {
  return { type: GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST, payload };
};

export const getPointApiPointHistorySearchSuccess = (payload) => {
  return { type: GET_POINT_API_POINT_HISTORY_SEACRH_SUCCESS, payload };
};

export const getPointApiPointHistorySearchFailed = (payload) => {
  return { type: GET_POINT_API_POINT_HISTORY_SEARCH_FAILED, payload };
}

export const getPointApiCsvTotalPointDailyReportRequest = (payload) => {
  return { type: GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST, payload};
};

export const getPointApiCsvTotalPointDailyReportSuccess = (payload) => {
  return { type: GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_SUCCESS, payload };
};

export const getPointApiCsvTotalPointDailyReportFailed = (payload) => {
  return { type: GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_FAILED, payload };
};

export const getPointApiCsvTotalPointDailyDownloadRequest = (payload) => {
  return { type: GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST, payload };
};

export const getPointApiCsvTotalPointDailyDownloadSuccess = () => {
  return { type: GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_SUCCESS };
};

export const getPointApiCsvTotalPointDailyDownloadFailed = (payload) => {
  return { type: GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_FAILED, payload };
};

export const getPointApiTransferHistoryDailyReportRequest = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_REQUEST, payload };
};

export const getPointApiTransferHistoryDailyReportSuccess = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_SUCCESS, payload };
};

export const getPointApiTransferHistoryDailyReportFailed = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_FAILED, payload };
};

export const getPointApiTransferHistoryDailyDownloadRequest = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_REQUEST, payload };
};

export const getPointApiTransferHistoryDailyDownloadSuccess = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_SUCCESS, payload };
};

export const getPointApiTransferHistoryDailyDownloadFailed = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_FAILED, payload };
};

export const getPointApiTransferHistoryMonthlyReportRequest = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_REPORT_REQUEST, payload };
};

export const getPointApiTransferHistoryMonthlyReportSuccess = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_MOMTHLY_REPORT_SUCCESS, payload };
};

export const getPointApiTransferHistoryMonthlyReportFailed = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_REPORT_FAILED, payload };
}

export const getPointApiTransferHistoryMonthlyDownloadRequest = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_REQUEST, payload };
};

export const getPointApiTransferHistoryMonthlyDownloadSuccess = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_SUCCESS, payload };
};

export const getPointApiTransferHistoryMonthlyDownloadFailed = (payload) => {
  return { type: GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_FAILED, payload };
};