export default {
  global: {
    appTitle: "Marketing Center",
    dashBoardTitle: "Marketing Dashboard",
    userManager: "Users Manager",
    userApproval: "User approval",
    userList: "User list",
    inviteUser: "Invite user",
    targetListSpool: "Target List Spool",
    customer: "Customer",
    servicer: "Servicer",
    targetListSpoolLog: "Target list spool log",
    aiSupportedTools: "AI Supported Tools",
    similarImageSearch: "Similar image search",
    personalRecommendation: "Recommendation",
    customerSegmentation: "Customer segmentation",
    servicerSegmentation: "Servicer segmentation",
    campaignManagement: "Campaign management",
    coupon: "Coupon",
    total: "Total",
    noResultFound: "No results found!",
    day: "Day",
    add: "Add",
    update: "Update",
    hogugu: "Hogugu",
    nailie: "Nailie",
    tanomel: "Tanomel",
    hairlie: "Hairlie",
    customerRecommendationMenu: "Customer To Menu",
    customerRecommendation: "Customer To Servicer",
    servicerMatchingScore: "Servicer matching score",
    menuMatchingScore: "Menu Matching Score",
    segmentation: {
      segment: "Segment",
      description: "Description"
    },
    copyRight: {
      copyRightInfo: "Copyright © 2020 C2C Tech Hub. All Rights Reserved.",
      timezone: "The date and time on this website is Japan Standard Time (UTC + 9)."
    },
    point: "Point",
    pointConfig: "Point Config",
    pointConfigSecretKey: "Product Secret Key",
    pointConfigExpiryDuration: "Point Expiry Duration",
    pointProductConfiguration: "Product Configuration",
    pointExchangeRate: "Point Exchange Rate",
    unRegistered: "Unregistered",
    pointTransferRate: "Point Transfer Rate",
    searchTotalPoint: "Search User Total Point",
    pointHistorySearch: "Search Point History",
    totalPointDaily: "Total Point Daily",
    transferPointHistoryDaily: "Transfer Point History Daily",
    transferPointHistoryMonthly: "Transfer Point History Monthly",
    invitationCoupon: "Invitation Coupon",
    couponSetting: "Coupon Setting",
    couponSettingSecretKey: "Secret Key",
    imageSize: "Image Size",
    couponType: "Coupon Type",
    tag: "Tag",
    registerCouponV1: "Register Coupon V1"
  },
  language: {
    en: "English",
    ja: "Japanese"
  },
  menuAccount: {
    logOut: "Log out",
    profile: "Profile",
    productRegister: "Product Register",
    displaySetting: "Display Setting",
    superAdminRegister: "Super Admin Register"
  },
  action: {
    runSearch: "Run search",
    save: "Save",
    saveAndActive: "Save And Active",
    close: "Close",
    export: "Export",
    search: "Search",
    submit: "Submit",
    ok: "OK",
    cancel: "Cancel",
    approval: "Approve",
    delete: "Delete",
    edit: "Edit",
    send: "Send",
    update: "Update",
    create: "Create",
    reset: "Reset",
    yes: "Yes",
    no: "No",
    select: "Select",
    reviewAndSend: "Review details and send",
    reviewAndSave: "Review details and save",
    setting: "Setting",
    design: "Design",
    copyTemplate: "Copy template",
    import: "Import",
    copySchedule: "Copy Schedule",
    stop: "Stop",
    log: "Log",
    activate: "Activate",
    deActivate: "Deactivate",
    addCondition: "Add Condition",
    view: "View",
    saveAndAplly: "Save And Apply",
    createInvitationCouponSetting: "Create",
    modalOk: "Ok",
    modalCancel: "Cancel"
  },
  placeholder: {
    noData: "No Data",
    pleaseEnter: "Please Enter",
    pleaseEnterInteger: "Please enter an integer",
    pleaseSelect: "Please Select",
    searchToSelect: "Search to Select",
    pleaseSelectMonth: "Please Select Month",
    couponCode: "Please input within 20 characters limit",
    couponTitle: "Please input within 20 characters limit",
    couponSelect: "Please select coupon",
    timeSelect: "HH:mm",
    tagName: "Please input within 50 characters limit"
  },
  content: {
    targetListSpool: {
      selectTargetListFromView: "Select Target List From Views",
      selectTargetListFromProcedure: "Select Target List Store Procedure",
      customerTitle: "Target List Spool - Customer",
      servicerTitle: "Target List Spool - Servicer",
      logTitle: "Target List Spool Log",
      logTab1: "Target list spool log",
      logTab2: "Target list spooled",
      targetListSelectFiledTitle: "Target list extraction template",
      confirmExport: "Do you want export data?",
      saveTitle: "Save as Target spool list",
      templateName: "Template name",
      param: "Parameter",
      name: "Name",
      description: "Description",
      findNotFound: "No results found!",
      logId: "Log ID",
      logName: "Log name",
      exporter: "Exporter",
      procedureName: "Procedure name",
      targetType: "Target type",
      targetList: "Target list",
      exportDate: "Export date",
      totalEmail: "Total email",
      totalSent: "Total sent",
      totalSentFail: "Total send fail",
      totalOpened: "Total opened",
      totalClicked: "Total clicked",
      userId: "User ID",
      originalId: "Original ID",
      email: "Email",
      status: "Status",
      openMail: "Open mail",
      clickToLink: "Link to click",
      confirmMoveToSendgridTab: "Do you want to send email to customer by Sendgrid?",
      customerTabName: "Customer List",
      servicerTabName: "Servicer List",
      clickItems: {
        clicked: "CLICKED",
        notClick: "NOT CLICK"
      },
      openItems: {
        opened: "OPENED",
        notOpen: "NOT OPEN"
      },
      senderTab: {
        title: "Sender",
        createNewSender: "Create new sender",
        confirmDeleteSender: "Delete this sender?",
        checkVerifyEmail:
          "We now need to verify your email address. We've sent an email to %{email} to verify your address. Please click the「Verify Sender Identity」button in that email to continue.",
        table: {
          no: "No",
          fromName: "From name",
          sender: "Sender",
          address: "Address",
          nickName: "Nick name",
          verified: "Verified",
          action: "Action",
          from: "FROM",
          reply: "REPLY",
          verifyItems: {
            verified: "VERIFIED",
            notVerify: "NOT VERIFY"
          }
        },
        form: {
          title: "Add a Sender",
          fromName: "From name",
          fromEmailAddress: "From email address",
          replyTo: "Reply to",
          companyAddress: "Company address",
          city: "City",
          zipCode: "Zip code",
          country: "Country",
          nickName: "Nick name",
          explain: `You're required to include your contact information, including a physical mailing address,
          inside every promotional email you send in order to comply with anti-spam laws such as CAN-SPAM and CASL.
          You'll find replacement tags for this information in the footer of  all the email designs SendGrid provides.`,
          fromEmailAddressExplain: `If your email domain doesn't match one of your authenticated domains,\n
          you'll need to verify this email address before using this sender.\n
          We'll send this email address a verification email after you save this sender.`
        }
      },
      singleSendTab: {
        title: "Single Send",
        createNewSingleSend: "Create a single send",
        confirmDeleteSingleSend: "Delete this single send?",
        errorBySenderDeleted: "Sender object has been deleted or invalidated. Please check again.",
        table: {
          no: "No",
          subject: "Subject",
          createdBy: "Created By",
          action: "Action"
        },
        form: {
          title: "Create a Single Send",
          previewTitle: "Single send detail",
          singleSendName: "Single send name",
          fromSender: "From sender",
          subject: "Subject",
          sendTo: "Send to",
          unsubscribeGroup: "Unsubscribe group",
          confirmCloseSetting: "Do you want to cancel the creation single send?"
        },
        finish: {
          title: "Finish",
          text1:
            "The marketing center has finished sending emails to the customer by %{email} contact. Here is a quick summary:",
          text2: "Send to: %{sendToTal}",
          text3: "Unsubscribe group: %{unsubscribeGroupTotal}",
          text4: "This action was initiated on %{date}."
        }
      },
      mailTemplateTab: {
        form: {
          editTitle: "Edit Email Template",
          previewTitle: "Preview Email Template",
          title: "Create Email Template",
          confirmCloseSetting: "Do you want to cancel the creation email template?"
        },
        tabTitle: "Mail Template",
        table: {
          no: "No",
          subject: "Mail Template Name",
          action: "Action",
          createdBy: "Created By"
        },
        subject: "Mail Template Name",
        emailDetail: "Email Detail",
        confirmDelete: "Do you want to delete this email template?",
        emailTemplateDeleteSuccess: "Deleted Email Template Successfully.",
        createTemplate: "Create Template",
        coupon: "Coupon",
        discountAmount: "Discount Amount",
        startFrom: "Start Form",
        endTo: "End To",
        couponInvalid:
          "The selected coupon cannot be displayed because it has been deleted or has expired. Please check the email body and coupon information again."
      },
      sendSchedule: {
        form: {
          target: "Target",
          storeProcedureName: "Store Procedure Name",
          extractionCondition: "Extraction Condition ",
          preview: "Preview",
          settingType: "Setting Type",
          period: "Period",
          from: "From",
          to: "To",
          settingSender: "Setting Sender",
          settingSingleSend: "Setting Single send",
          previewTemplate: "Preview Template",
          listViews: "List Views"
        },
        table: {
          no: "No",
          targetType: "Target Type",
          type: "Procedure Name",
          extractionTemplate: "Extraction Template",
          sendingScheduleName: "Send Schedule Name",
          status: "Status",
          schedule: "Schedule",
          subject: "Subject",
          action: "Action"
        },
        resultList: "Result List",
        pageTitle: "List Sending Schedule",
        tab1Title: "Sending Schedule",
        tab2Title: "Setting sending schedule",
        confirmDelete: "Do you want to delete this schedule ?",
        confirmStop: "Do you want to stop this schedule ?",
        createSendingSchedule: "Create Sending Schedule",
        createNewTemplate: "Create New Template",
        sendScheduleStopSuccess: "Stop send schedule successfully.",
        sendScheduleDeleteSuccess: "Deleted send schedule successfully.",
        detail: {
          detailSendSchedule: "Sending Schedule Detail ",
          sendgridScheduleName: "Sending Schedule Name",
          targetListSpoolCondition: "Extraction Condition",
          sendgridSchedule: "Schedule",
          sender: "Sender",
          mailTemplate: "Mail Template",
          startFrom: "Start From",
          endTo: "End To",
          ok: "OK"
        }
      },
      spAliasName: {
        customer_register_date: "List users by register date",
        v2_customer1: "List users by verified and status",
        customer_nailie_user_birthday: "List users by birthday",
        customer_nailie_user_never_booked: "List of the user have never booked",
        customer_nailie_user_booked_once: "List users have booked once",
        servicer_register_date: "List users by register date",
        v2_servicer1: "List users by verified and status",
        servicer_nailie_user_birthday: "List users by birthday",
        servicer_nailie_user_never_booked: "List of the user have never booked",
        servicer_nailie_user_booked_once: "List users have booked once"
      },
      spParamName: {
        register_date_from: "From registration date",
        register_date_to: "To registration date",
        is_verified: "Verification status",
        status: "Status",
        birthday: "Month of birthday",
        register_date: "From Sending date"
      },
      extraCondition: {
        and: "AND"
      }
    },
    user: {
      registerTitle: "User Register",
      listTitle: "List Users",
      approvalTitle: "User List For Approval",
      userEditTitle: "Edit User",
      inviteTitle: "Invite User",
      profileTitle: "User Profile",
      settingTitle: "Display Setting",
      no: "No",
      fullname: "Full name",
      nickname: "Nick name",
      email: "Email address",
      phone: "Phone",
      status: "Status",
      role: "Role",
      product: "Product",
      register: "Register",
      action: "Action",
      emailIsWaitingApproved: "Your account is waiting for approved!",
      anotherLoginAcc: "Login with Another Account",
      confirmApproval: "Approval this account?",
      confirmDelete: "Delete this account?",
      accountUnavailable: "Your account is unavailable!",
      invalidInviteLink: "Invite link is invalid!",
      inviteLinkSent: "An email invitation sent to your email. Please check your email!",
      userRegisterSuccess: "Register user success",
      userDeleteSuccess: "Delete user success",
      userApprovalSuccess: "Approval user success",
      userUpdateSuccess: "Update user success",
      userInviteSuccess: "Invite user success",
      userDeleteByProduct: "Your account has been deleted from the %{product}",
      userSettingUpdateSuccess: "Update setting success",
      productCode: "Product code",
      productName: "Product name",
      registerCouponServiceTitle: "Register Coupon Service",
      registerCouponServiceTitleV1: "Register Coupon Service V1",
      registerPointServiceTitle: "Register Point Service",
      expiryDuration: "Expiry duration",
      extendRollingExpiryDateUsePoint: "Extend rolling expiry date when use pt",
      extendRollingExpiryDateTransferPoint: "Extend rolling expiry date when transfer pt",
      writerDBHost: "Writer DB host",
      readDBHost: "Read DB host",
      userName: "Username",
      passWord: "Password",
      databaseName: "Database name",
      redisHost: "Redis host",
      registerProductTitle: "Register Product",
      productNameEN: "Product name (En)",
      productNameJP: "Product name (Jp)",
      urlOfAdminSite: "URL of Admin Site",
      callbackURLOfAdminSite: "Callback URL of Admin Site",
      registerSuperAdminTitle: "Register Super Admin",
      clientId: "Client ID",
      clientSecret: "Client Secret",
      auth0Identifier: "Auth0 Api Identifier",
      appName: "App name",
      appId: "App Id",
      passphrase: "Passphrase",
      downloadPrivateKey: "Download private key",
      userId: "User ID"
    },
    aiSupportedTools: {
      personalRecommendation: {
        customerTitle: "Customer To Servicer",
        customerMenuTitle: "Customer Recommendation Menu",
        menuMatchingScoreTitle: "Menu Matching Score",
        customerTab1: "Recommendation",
        customerTab2: "Recommendation detail",
        servicerMatchingScoreTitle: "Servicer matching score",
        table: {
          no: "No",
          customerId: "Customer ID",
          fullName: "Full name",
          nickName: "Nick name",
          email: "Email",
          servicerId: "Servicer ID",
          score: "Score",
          rank: "Rank",
          area: "Area",
          menuId: "Menu ID",
          title: "Title",
          servicer: "Servicer",
          price: "Price",
          timeDuration: "Time Duration"
        }
      },
      customerSegmentation: {
        customerSegmentationTitle: "Customer Segmentation",
        customerSegmentationDetailTitle: "Customer Segmentation Detail",
        customerSegmentationRFMDiagrams: "RFM Diagrams",
        table: {
          no: "No",
          className: "Class Name",
          quantity: "Quantity",
          customerName: "Customer Name",
          recency: "Recency",
          frequency: "Frequency",
          monetary: "Monetary"
        }
      },
      servicerSegmentation: {
        servicerSegmentationTitle: "Servicer Segmentation",
        customerTab1: "Servicer Segmentation",
        customerTab2: "Servicer Segmentation Detail",
        customerTab3: "RFM Diagrams",
        table: {
          no: "No",
          className: "Class Name",
          quantity: "Quantity",
          servicerName: "Servicer Name",
          recency: "Recency",
          frequency: "Frequency",
          monetary: "Monetary"
        }
      },
      charts: {
        distributionOfSegmentation: "Segmentation",
        descriptionOfTheSegments: "Description of the segments",
        distributionOfRecency: "Distribution Of Recency",
        distributionOfFrequency: "Distribution Of Frequency",
        distributionOfMForeachFAndR: "Distribution of M foreach F and R",
        distributionOfTheServicersAmongTheSegments:
          "Distribution of the servicers among the segments",
        distributionOfTheCustomersAmongTheSegments:
          "Distribution of the customers among the segments"
      }
    },
    campaignManagement: {
      coupon: {
        table: {
          no: "No",
          code: "Code",
          title: "Title",
          amount: "Amount",
          start: "Start",
          end: "End",
          status: "Status",
          type: "Type",
          action: "Action",
          transactionId: "TransactionId",
          customerId: "CustomerId",
          servicerId: "ServicerId",
          couponAmount: "CouponAmount",
          usedAt: "Used At",
          expiredAt: "Expired At",
          completedAt: "Completed At",
          issuedAt: "Issued At"
        },
        couponList: "Coupon List",
        usageCouponList: "Usage List",
        usageCouponListOf: "Usage of {{coupon}}",
        issueCouponList: "Issue List",
        issueCouponListOf: "Issue of {{coupon}}",
        createNewCoupon: "Create New Coupon",
        updateCoupon: "Update Coupon",
        confirmActivate: "Do you want to activate this coupon?",
        confirmDeActivate: "Do you want to deactivate this coupon?",
        confirmDelete: "Do you want to delete this coupon?",
        coupon_information: "Coupon Information",
        announcement_information: "Additional Campaign Information",
        coupon_banner_image: "Coupon banner image",
        campaign_banner_image: "Campaign banner image",
        campaign_link: "Campaign link",
        description: "Description",
        couponCode: "Coupon code",
        conditions: "Conditions",
        couponChangeStatusSuccess: "Update status coupon success",
        couponDeleteSuccess: "Deleted coupon successfully.",
        secretKey: "Secret key",
        discountType: "Discount type",
        fixedAmount: "Fixed amount (¥)",
        rate: "Rate(%)",
        tag: "Tag",
        rules: {
          minimumAmount: "Minimum Amount",
          total_count: "Total Count",
          limitPerUser: "Limit Per User",
          allowServicerIds: "Allow Servicer Ids",
          allowCustomerIds: "Allow Customer Ids",
          restrictServicerIds: "Restrict Servicer Ids",
          restrictCustomerIds: "Restrict Customer Ids",
          issueCouponNumber: "Issue Coupon Number",
          availableDays: "Available Days",
          maxServiceUsage: "Max Service Usage",
          minServiceUsage: "Min Service Usage",
          endIssued: "End Issued",
          startDate: "Start Date",
          endDate: "End Date",
          startIssue: "Start Issue",
          totalIssuableCount: "Total Issuable Count",
          maxServiceUsageForIssue: "Max Service Usage For Issue",
          minServiceUsageForIssue: "Min Service Usage For Issue",
          allowMenuIds: "Allow Menu Ids",
          availableBookingFrom: "Available Booking From",
          availableBookingTo: "Available Booking To",
          availableBookingStartTimeFrom: "Available Booking Start Time From",
          availableBookingStartTimeTo: "Available Booking Start Time To",
          ableToIssueMultiple: "Able To Issue Multiple",
          maxDiscountAmount: "Max Discount Amount"
        },
        type: {
          public: "Public",
          private: "Private"
        }
      },
      point: {
        secretKey: "Secret key",
        year: "Year",
        month: "Month",
        expiryDuration: "Expiry Duration",
        pointExchangeRate: "Point Exchange Rate",
        currency: "Currency (3 Charcters)",
        pointToCurrency: "Point -> Currency",
        currencyToPoint: "Currency -> Point",
        confirmDelete: "Do you want to delete it?",
        deletedPointExchangeRateSuccess: "It has been deleted!",
        pointTransferRate: "Point Transfer Rate",
        product: "Product",
        transferRate: "Transfer rate",
        minimumPoint: "Minimum point",
        multiple: "Multiple",
        searchPointHistory: "Search Point History",
        userId: "User ID",
        referenceId: "Reference ID",
        createdFrom: "Created From",
        createdTo: "To",
        addUsePoint: "Add/Use Point",
        expiryType: "Expiry Type",
        status: "Status",
        transferable: "Transferable",
        notTransferable: "Not Transferable",
        point: "Point",
        expiryDate: "Expiry Date",
        createdDate: "Created Date",
        approved: "Approved",
        cancelled: "Cancelled",
        add: "Add",
        use: "Use",
        nonExp: "Non-exp",
        rolling: "Rolling",
        fixed: "Fixed",
        searchTotalPoint: "Search User Total Point",
        updatedFrom: "Updated From",
        updatedTo: "To",
        rollingExpiryFrom: "Rolling expiry From",
        rollingExpiryTo: "To",
        fixedExpiryFrom: "Fixed expiry From",
        fixedExpiryTo: "To",
        totalPoint: "Total point",
        nonExpPoint: "Non-exp point",
        rollExpPoint: "Roll-exp point",
        rollExpDate: "Roll-exp date",
        fixedExpPoint: "Fixed-exp point",
        fixedExpDate: "Fixed-exp date",
        totalPointDaily: "Total Point Daily",
        file: "File",
        lastModified: "Last Modified",
        download: "Download",
        transferPointHistoryDaily: "Transfer Point History Daily",
        transferPointHistoryMonthly: "Transfer Point History Monthly"
      },
      invitation: {
        invitationCouponSetting: "Invitation Coupon Setting",
        invitationCoupon: "Invitation Coupon",
        incentiveCoupon: "Incentive Coupon",
        invitationLink: "Invitation",
        incentiveLink: "Incentive",
        codes: "Codes",
        confirmDelete: "Do you want to delete this invitation ?",
        invitation: "Invitation",
        incentive: "Incentive",
        table: {
          no: "No",
          code: "Code",
          customerId: "CustomerId",
          invitationCoupons: "Invitation Coupons",
          incentiveCoupons: "Incentive Coupons",
          createdDate: "Create Date",
          action: "Action"
        },
        type: {
          invitation: "Invitation",
          incentive: "Incentive"
        },
        activatePopupTitle: "Are you sure you want to activate this campaign?",
        activatePopupBody:
          "By activating, the user can request the Invitation Code and referral coupon can be issued to users.",
        deactivatePopupTitle: "Are you sure you want to deactivate this campaign?",
        deactivatePopupBody:
          "Deactivated campaign will prevent the user from acquiring their Invitation Code and new referral coupon can no longer be issued to the users.",
        deletePopupTitle: "Are you sure you want to delete this campaign?",
        deletePopupBody:
          "Once the campaign is deleted, all issued coupons to the users shall be invalid. The user can no longer use their coupons as well as acquiring their invitation code. Please consider carefully as the action can not be undone once executed!"
      },
      couponSetting: {
        imageSize: "Image Size",
        couponBannerSmallSize: "Coupon Banner (Small Size)",
        couponBannerLargeSize: "Coupon Banner (Large Size)",
        announcementBannerSmallSize: "Announcement Banner (Small Size)",
        announcementBannerLargeSize: "Announcement Banner (Large Size)",
        height: "Height",
        width: "Width",
        secretKey: "Secret key",
        couponType: "Coupon Type",
        privateCoupon: "Private coupon",
        publicCoupon: "Public coupon",
        invitationIncentiveCoupon: "Invitation / Incentive coupon",
        available: "Available",
        unavailable: "Unavailable",
        updateCouponTypeSettingSuccess: "Update coupon type setting success.",
        tagList: "Tag List",
        createNewTag: "Create New Tag",
        confirmDelete: "Do you want to delete this tag ?",
        name: "Name",
        table: {
          no: "No",
          name: "Name",
          action: "Action"
        },
        createTag: "Create Tag",
        updateTag: "Update Tag"
      }
    }
  },
  message: {
    dayFromIsNotBeforeDayTo: "You have selected an incorrect period.",
    error_past_day: "You cannot select a past date.",
    error: "Error",
    noNetwork: "No Network",
    isRequired: "This field is required",
    isInvalid: "This field is invalid",
    saveSuccess: "Save Sucessfully!",
    deleteSuccess: "Delete successfully!",
    errorUndefined: "Undefined Error",
    badRequest: "Bad request",
    isNumber: "This field must be a number",
    isMinValue: "Make sure this field is greater than 0",
    registerSuccess: "Register Successfully!",
    productCodeFormat: "This field must be a-z and 0-9",
    productNameFormat: "This field must be a-z and A-Z and 0-9",
    notIncluceSpaceFormat: "This field must not include white space",
    minRateAmountInput: "The minimum of amount value is 1",
    maxRateAmountInput: "The maximum of amount value is 100",
    required: {
      image_coupon: "Please register the image for the campaign",
      email: "Email address is required",
      fullname: "Full name is required",
      nickname: "Nick name is required",
      phone: "Phone is required",
      name: "Name is required",
      role: "Role is required",
      product: "Product is required",
      amount: "Amount is required"
    },
    invalid: {
      email: "Email address is invalid",
      fullname: "Full name is invalid",
      nickname: "Nick name is invalid",
      amount: "Amount is invalid"
    },
    errorSaveTargetNoData:
      "You can't register to Sendgrid because there is no email in the target list.",
    maxsingleSendName: "Single send name should be 80 characters",
    errorNoValueMonthYear: "You must not set 00 year 00 month",
    noInvitationCouponSetting: "There is no invitation coupon setting",
    duplicateCurrencyValue: "You already have a currency setting",
    inviteSuperAdminFail: "Invite super admin failed",
    minLengthSecretKeyValue: "Secret key should be at least 24 characters",
    createTagSuccess: "Create tag successfully",
    updateTagSuccess: "Update tag successfully",
    maxLengthTagName: "The maximum of tag name is 50 characters.",
    associateTagWithCouponSuccess: "Associate tag with coupon successfully.",
    maxLengthAppId: "The maximum of app id is 15 characters.",
    maxLengthAppName: "The maximum of app name is 20 characters.",
    maxLengthPassphrase: "The maximum of passphrase is 20 characters.",
    minLength: "The minimum length is {{minLength}}.",
    maxLength: "The maximum length is {{maxLength}}.",
    matchRegex: "Please provide a valid value. Only accept {{descriptionRegex}}.",
    coupon: {
      codeRegexDescription: "uppercase letter and number (A-Z 0-9)",
    }
  },
  apiResponseMessage: {
    failValidate: "Did not pass validation",
    email_registed: "Email address is already exists",
    registed: "This field is already exists",
    invalidRequest: "Could not access due to an error.",
    super_admin_exists: "Email address already is super admin in system",
    noSecretKeyFound: "No secret key found.",
    theCurrentProductHasNotRegisteredCouponService:
      "The current product has not registered coupon service"
  },
  sendgrid: {
    event: {
      processed: "Processed",
      delivered: "Delivered",
      bounce: "Bounces",
      deferred: "Deferred",
      blocked: "Blocks",
      dropped: "Drops",
      spamreport: "Spam Reports",
      unsubscribe: "Unsubscribes",
      group_unsubscribe: "Group Unsubscribe",
      group_resubscribe: "Group Resubscribe"
    }
  }
};
