import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Table as AntTable, Empty, Button, Input, DatePicker, Select } from "antd";
import i18n from "i18n";
import LoadingIndicator from "components/LoadingIndicator";
import "./style.less";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";

import constants from "utils/constants";

const { Option } = Select;

const RenderSearchField = (input) => {
  const { key, searchType, submit, selectItems, dispatch, searchValues, defaultValue } = input;

  if (searchType === constants.SEARCH_TYPE.SELECT) {
    return (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        suffixIcon={false}
        bordered={false}
        listHeight={150}
        defaultValue={defaultValue != "undefined" ? defaultValue : null}
        onChange={(value) => {
          searchValues.filter[key] = value.length ? value : null;
        }}
      >
        {_.map(selectItems, (item) => (
          <Option key={item.key || item.value}>{`${item.name}`}</Option>
        ))}
      </Select>
    );
  }

  if (searchType === constants.SEARCH_TYPE.DATE_PICKER) {
    return (
      <DatePicker
        placeholder=""
        format={key == "birthday" ? constants.FORMAT.MONTH_VIEW : constants.FORMAT.DATE_VIEW}
        suffixIcon={false}
        bordered={false}
        picker={key == "birthday" ? "month" : ""}
        defaultValue={defaultValue ? moment(defaultValue, constants.FORMAT.SINGLE_SEND_DATE) : null}
        onChange={(date) => {
          if (key == "birthday") {
            searchValues.search[key] = !date
              ? null
              : moment(date).format(constants.FORMAT.SINGLE_SEND_MONTH);
          } else {
            searchValues.search[key] = !date
              ? null
              : moment(date).format(constants.FORMAT.SINGLE_SEND_DATE);
          }
        }}
      />
    );
  }

  return (
    <Input
      allowClear
      bordered={false}
      defaultValue={defaultValue ? defaultValue : ""}
      onChange={(e) => {
        searchValues.search[key] = e.target.value;
      }}
      onPressEnter={(e) => {
        searchValues.search[key] = e.target.value;
        submit({ dispatch });
      }}
    />
  );
};

const RenderShowSearchIcon = (input) => {
  const { setShowSearch, showSearch, loading } = input;

  return (
    <div className="show-search-icon">
      <Button
        className="search-table-btn"
        icon={<SearchOutlined style={{ color: "black" }} />}
        disabled={loading}
        onClick={() => {
          if (showSearch === "") {
            setShowSearch("hidden-search");
          } else {
            setShowSearch("");
          }
        }}
      />
    </div>
  );
};

const TableCustomer = ({
  localeEmptyText,
  totalText,
  loading,
  allowSearch,
  columns,
  submit,
  searchValues,
  ...props
}) => {
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState("hidden-search");

  const handleTableChange = (pagination, filter, sorter) => {
    if (submit) {
      submit({ dispatch, page: pagination.current, filter, sorter, isSort: true });
    }
  };

  if (allowSearch) {
    _.forEach(columns, (value, index) => {
      columns[index] = {
        title: value.title,
        dataIndex: value.dataIndex,
        key: value.key,
        showSorterTooltip: value.showSorterTooltip,
        sorter: value.sorter,
        children: [
          {
            key: value.key,
            dataIndex: value.dataIndex,
            ellipsis: value.ellipsis,
            width: value.width,
            render: value.render,
            fixed: value.fixed,
            className: value.className,
            title: !columns[index].searchType ? null : (
              <div className="search-form">
                {RenderSearchField({
                  defaultValue: value.defaultValue,
                  searchType: value.searchType,
                  key: value.dataIndex,
                  selectItems: value.selectItems,
                  submit,
                  dispatch,
                  searchValues
                })}
                <Button
                  type="link"
                  icon={<SearchOutlined />}
                  style={{ width: 40 }}
                  onClick={() => {
                    submit({ dispatch });
                  }}
                />
              </div>
            )
          }
        ]
      };
    });
  }
  return (
    <div style={{ maxHeight: 2500 }} className="my-table">
      {allowSearch && RenderShowSearchIcon({ setShowSearch, showSearch, loading })}
      <AntTable
        {...props}
        className={
          allowSearch
            ? `${_.get(props, "className", "")} table-search ${showSearch}`
            : _.get(props, "className", "")
        }
        loading={{
          spinning: loading,
          indicator: <LoadingIndicator />
        }}
        locale={{
          emptyText: _.isEmpty(localeEmptyText) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={i18n.t("placeholder.noData")}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={localeEmptyText} />
          )
        }}
        columns={columns}
        onChange={handleTableChange}
        scroll={{
          x: _.get(props, "scroll.x"),
          y: !allowSearch
            ? _.get(props, "scroll.y")
            : showSearch === ""
            ? _.get(props, "scroll.y") - 63
            : _.get(props, "scroll.y")
        }}
      />
    </div>
  );
};

TableCustomer.propTypes = {
  columns: PropTypes.array,
  search: PropTypes.object,
  loading: PropTypes.bool,
  totalText: PropTypes.string,
  submit: PropTypes.func,
  allowSearch: PropTypes.bool,
  searchValues: PropTypes.object,
  localeEmptyText: PropTypes.string
};

export default memo(TableCustomer);
