import { Login, Logout } from "containers/Auth";
import Dashboard from "containers/Dashboard";
import {
  CustomerRecommendation,
  CustomerRecommendationMenu,
  CustomerSegmentation,
  MenuMatchingScore,
  ServicerMatchingScore,
  ServicerSegmentation
} from "containers/Dashboard/AISupportedTool";
import {
  Coupon,
  CouponSettingSecretKey,
  ImageSize,
  InvitationCoupon,
  PointConfigExpiryDuration,
  PointConfigSecretKey,
  PointExchangeRate,
  PointTransferRate,
  SearchPointHistory,
  SearchTotalPoint,
  TotalPointDaily,
  TransferHistoryDaily,
  TransferHistoryMonthly,
  CouponTypeSetting,
  Tag
} from "containers/Dashboard/CampaignManagement";
import Home from "containers/Dashboard/Home/Loadable";
import {
  EmailTemplate,
  SendSchedule,
  TargetListSpoolCustomer,
  TargetListSpoolLog
} from "containers/Dashboard/TargetListSpool";
import {
  RegisterCouponService,
  RegisterCouponServiceV1,
  RegisterPointService,
  RegisterProduct,
  UserApproval,
  UserInvite,
  UserList,
  UserProfile,
  UserSetting,
  SuperAdminRegister
} from "containers/Dashboard/User";
import Guest from "containers/Guest";
import { AcceptInvite, UserRegister, UserUnavailable } from "containers/Guest/User";
import React from "react";
import { Navigate } from "react-router-dom";
import helper from "utils/helpers";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoute";

const RedirectToDashboard = () => {
  return <Navigate to={helper.isGuest() ? "/login" : "/"} replace />;
};

const dashboardRoutes = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/target-list-spool/email-template",
    element: <EmailTemplate />
  },
  {
    path: "/target-list-spool/:type",
    element: <TargetListSpoolCustomer />
  },
  {
    path: "/target-list-spool/log",
    element: <TargetListSpoolLog />
  },
  {
    path: "/target-list-spool/send-schedule",
    element: <SendSchedule />
  },
  {
    path: "/user/list",
    element: <UserList />
  },
  {
    path: "/user/approval",
    element: <UserApproval />
  },
  {
    path: "/user/invite",
    element: <UserInvite />
  },
  {
    path: "/my-profile",
    element: <UserProfile />
  },
  {
    path: "/ai-support-tool/personal/servicer-matching-score",
    element: <ServicerMatchingScore />
  },
  {
    path: "/ai-support-tool/personal/menu-matching-score",
    element: <MenuMatchingScore />
  },
  {
    path: "/ai-support-tool/personal/customer-recommendation-servicer",
    element: <CustomerRecommendation />
  },
  {
    path: "/ai-support-tool/personal/customer-recommendation-menu",
    element: <CustomerRecommendationMenu />
  },
  {
    path: "/ai-support-tool/customer-segmentation",
    element: <CustomerSegmentation />
  },
  {
    path: "/ai-support-tool/service-segmentation",
    element: <ServicerSegmentation />
  },
  {
    path: "/logout",
    element: <Logout />
  },
  {
    path: "/campaign-management/coupon",
    element: <Coupon />
  },
  {
    path: "/campaign-management/invitation-coupon",
    element: <InvitationCoupon />
  },
  {
    path: "/campaign-management/coupon-setting/product-secret-key",
    element: <CouponSettingSecretKey />
  },
  {
    path: "/campaign-management/coupon-setting/image-size",
    element: <ImageSize />
  },
  {
    path: "/campaign-management/coupon-setting/coupon-type",
    element: <CouponTypeSetting />
  },
  {
    path: "/campaign-management/coupon-setting/tag",
    element: <Tag />
  },
  {
    path: "/setting",
    element: <UserSetting />
  },
  {
    path: "/campaign-management/point/point-config/secret-key",
    element: <PointConfigSecretKey />
  },
  {
    path: "/campaign-management/point/point-config/expiry-duration",
    element: <PointConfigExpiryDuration />
  },
  {
    path: "/campaign-management/point/point-config/point-rate",
    element: <PointExchangeRate />
  },
  {
    path: "/campaign-management/point/point-config/transfer-rate",
    element: <PointTransferRate />
  },
  {
    path: "/campaign-management/point/point-total-search",
    element: <SearchTotalPoint />
  },
  {
    path: "/campaign-management/point/point-history",
    element: <SearchPointHistory />
  },
  {
    path: "/campaign-management/point/total-point",
    element: <TotalPointDaily />
  },
  {
    path: "/campaign-management/point/transfer-history-daily",
    element: <TransferHistoryDaily />
  },
  {
    path: "/campaign-management/point/transfer-history-monthly",
    element: <TransferHistoryMonthly />
  },
  {
    path: "/register-coupon-service",
    element: <RegisterCouponService />
  },
  {
    path: "/register-coupon-service-v1/:id",
    element: <RegisterCouponServiceV1 />
  },
  {
    path: "/register-point-service",
    element: <RegisterPointService />
  },
  {
    path: "/register-product",
    element: <RegisterProduct />
  },
  {
    path: "/register-super-admin",
    element: <SuperAdminRegister />
  }
];

const guestRoutes = [
  {
    path: "/user/register",
    element: <UserRegister />
  },
  {
    path: "/user/unavailable",
    element: <UserUnavailable />
  },
  {
    path: "/user/invite",
    element: <AcceptInvite />
  }
];

const indexRoutes = [
  {
    path: "/login",
    element: <PublicRoute Component={Login} />
  },
  {
    path: "/login/callback",
    element: <PublicRoute Component={Login} isAuth0Callback={true} />
  },
  {
    path: "/guest",
    element: <PublicRoute Component={Guest} />,
    children: guestRoutes
  },
  {
    path: "/",
    element: <PrivateRoute Component={Dashboard} />,
    children: dashboardRoutes
  },
  {
    path: "*",
    element: <RedirectToDashboard />
  }
];

export default indexRoutes;
