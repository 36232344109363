import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Table as AntTable, Empty, Button, Input, DatePicker, Select } from "antd";
import i18n from "i18n";
import LoadingIndicator from "components/LoadingIndicator";
import "./style.less";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";

import constants from "utils/constants";
import TableField from "components/common/Table/TableField";
const { Option } = Select;

const RenderShowSearchIcon = (input) => {
  const { setShowSearch, showSearch, loading } = input;

  return (
    <div className="show-search-icon">
      <Button
        className="search-table-btn"
        icon={<SearchOutlined style={{ color: "black" }} />}
        disabled={loading}
        onClick={() => {
          if (showSearch === "") {
            setShowSearch("hidden-search");
          } else {
            setShowSearch("");
          }
        }}
      />
    </div>
  );
};

const Table = ({
  localeEmptyText,
  totalText,
  loading,
  allowSearch,
  columns,
  submit,
  searchValues,
  time,
  ...props
}) => {
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState("hidden-search");
  const handleTableChange = (pagination, filter, sorter) => {
    if (submit) {
      submit({ dispatch, page: pagination.current, filter, sorter, isSort: true, searchValues });
    }
  };

  useEffect(() => {
    if (time) {
      setShowSearch("hidden-search");
    }
  }, [time]);

  const handleChangeField = (value, type, fieldName) => {
    searchValues[type][fieldName] = value;
  };

  if (allowSearch) {
    _.forEach(columns, (value, index) => {
      columns[index] = {
        title: value.title,
        dataIndex: value.dataIndex,
        key: value.key,
        showSorterTooltip: value.showSorterTooltip,
        sorter: value.sorter,
        children: [
          {
            key: value.key,
            dataIndex: value.dataIndex,
            ellipsis: value.ellipsis,
            width: value.width,
            render: value.render,
            fixed: value.fixed,
            className: value.className,
            title: !columns[index].searchType ? null : (
              <div className="search-form">
                <TableField
                  searchType={value.searchType}
                  isMultiple={value.isMultiple}
                  fieldName={value.dataIndex}
                  selectItems={value.selectItems}
                  submit={submit}
                  dispatch={dispatch}
                  time={time}
                  onChange={handleChangeField}
                  defaultValue={value.defaultValue ? value.defaultValue : ""}
                />
                <Button
                  type="link"
                  icon={<SearchOutlined />}
                  style={{ width: 40 }}
                  onClick={() => {
                    submit({ dispatch });
                  }}
                />
              </div>
            )
          }
        ]
      };
    });
  }
  return (
    <div className="my-table">
      {allowSearch && RenderShowSearchIcon({ setShowSearch, showSearch, loading })}
      <AntTable
        {...props}
        className={
          allowSearch
            ? `${_.get(props, "className", "")} table-search ${showSearch}`
            : _.get(props, "className", "")
        }
        loading={{
          spinning: loading,
          indicator: <LoadingIndicator />
        }}
        locale={{
          emptyText: _.isEmpty(localeEmptyText) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={i18n.t("placeholder.noData")}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={localeEmptyText} />
          )
        }}
        columns={columns}
        onChange={handleTableChange}
        scroll={{
          x: _.get(props, "scroll.x"),
          y: !allowSearch
            ? _.get(props, "scroll.y")
            : showSearch === ""
            ? _.get(props, "scroll.y") - 63
            : _.get(props, "scroll.y")
        }}
      />
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  search: PropTypes.object,
  loading: PropTypes.bool,
  totalText: PropTypes.string,
  submit: PropTypes.func,
  allowSearch: PropTypes.bool,
  searchValues: PropTypes.object,
  localeEmptyText: PropTypes.string,
  time: PropTypes.number
};

export default Table;
