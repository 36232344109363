import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Tooltip, Button } from "antd";
import MaskedInput from "antd-mask-input";
import { useField } from "formik";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { forwardRef, memo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import constants from "utils/constants";
import "./style.less";
import Picker from "emoji-picker-react";
import OutsideEffect from "components/common/OutsideEffect";

const formItemLayout = {
  labelCol: {
    sm: { span: 6 }
  },
  wrapperCol: {
    sm: {
      span: 17,
      offset: 1
    }
  }
};

const phoneInputStyle = {
  large: {
    height: 40,
    width: "100%",
    fontSize: constants.FONT_SIZE,
    borderRadius: 2
  },
  medium: {
    height: 33,
    width: "100%",
    fontSize: constants.FONT_SIZE,
    borderRadius: 2
  }
};

const TextInput = forwardRef(
  (
    {
      label,
      maxLength,
      min,
      inputType,
      size = "large",
      allowClear = true,
      className = "",
      vertical = true,
      message = "",
      description,
      ...props
    },
    ref
  ) => {
    const [field, meta, helper] = useField(props);
    const [focus, setFocus] = useState(false);
    const isError = ((meta.touched || focus) && meta.error) || message;

    const renderInput = () => {
      if (inputType === "password") {
        return (
          <Input.Password
            maxLength={maxLength}
            size={size}
            allowClear={allowClear}
            ref={ref}
            {...field}
            {...props}
          />
        );
      } else if (inputType === "textarea") {
        return (
          <Input.TextArea
            maxLength={maxLength}
            allowClear={allowClear}
            {...field}
            {...props}
            ref={ref}
            onChange={(e) => helper.setValue(e.target.value)}
          />
        );
      } else if (inputType === "search") {
        return <Input.Search size={size} maxLength={maxLength} {...field} {...props} ref={ref} />;
      } else if (inputType === "maskInput") {
        return (
          <MaskedInput
            maxLength={maxLength}
            {...field}
            {...props}
            ref={ref}
            style={{ height: "40px" }}
          />
        );
      } else if (inputType === "number") {
        return (
          <InputNumber
            min={min}
            size={size}
            allowClear={allowClear}
            {...field}
            {...props}
            ref={ref}
            onChange={(data) => {
              const minValue = min ? min : 0;
              if (_.isNumber(data)) {
                helper.setValue(data);
              } else {
                helper.setValue(minValue);
              }
            }}
            style={{ width: "100%", fontSize: constants.FONT_SIZE }}
          />
        );
      } else if (inputType === "phone") {
        return (
          <PhoneInput
            country={"jp"}
            inputStyle={{ ...phoneInputStyle[size] }}
            {...field}
            onChange={(data) => helper.setValue(data)}
            onFocus={() => setFocus(true)}
            isValid={isError ? false : true}
          />
        );
      } else if (inputType === "emoji" || inputType === "emojiTextarea") {
        const [showEmojis, setShowEmojis] = useState(false);
        const [start, setStart] = useState(false);

        const onEmojiClick = (event, emojiObject) => {
          if (emojiObject.emoji) {
            let value = field.value || "";
            const newVal = value.substring(0, start) + emojiObject.emoji + value.substring(start);
            helper.setValue(newVal);
          }
        };
        const handleClickOutside = () => {
          setShowEmojis(false);
        };
        const handleKeyInput = (e) => {
          setStart(e.target.selectionStart);
        };

        if (inputType === "emoji") {
          return (
            <OutsideEffect onClickOutside={handleClickOutside}>
              <div className="emoji-input">
                <div className="row">
                  <div className="item-row-1">
                    <Input
                      maxLength={maxLength}
                      size={size}
                      allowClear={allowClear}
                      {...field}
                      {...props}
                      ref={ref}
                      onKeyUp={handleKeyInput}
                      onKeyDown={handleKeyInput}
                      onClick={handleKeyInput}
                    />
                  </div>
                  <div className="item-row-2">
                    <Button
                      className={showEmojis ? "active" : ""}
                      onClick={() => setShowEmojis(!showEmojis)}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          fill="none"
                          viewBox="-3 0 32 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      }
                    />
                  </div>
                </div>

                {showEmojis && (
                  <div className="picker">
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      disableSkinTonePicker={true}
                      groupNames={{ smileys_people: "PEOPLE" }}
                      native
                    />
                  </div>
                )}
              </div>
            </OutsideEffect>
          );
        }

        if (inputType === "emojiTextarea") {
          return (
            <OutsideEffect onClickOutside={handleClickOutside}>
              <div className="emoji-input">
                <div className="row">
                  <div className="item-row-1">
                    <Input.TextArea
                      maxLength={maxLength}
                      size={size}
                      onChange={(e) => helper.setValue(e.target.value)}
                      {...field}
                      {...props}
                      ref={ref}
                      onKeyUp={handleKeyInput}
                      onKeyDown={handleKeyInput}
                      onClick={handleKeyInput}
                    />
                  </div>
                  <div className="item-row-2">
                    <Button
                      className={showEmojis ? "active" : ""}
                      onClick={() => setShowEmojis(!showEmojis)}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          fill="none"
                          viewBox="-3 0 32 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      }
                    />
                  </div>
                </div>

                {showEmojis && (
                  <div className="picker">
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      disableSkinTonePicker={true}
                      groupNames={{ smileys_people: "PEOPLE" }}
                      native
                    />
                  </div>
                )}
              </div>
            </OutsideEffect>
          );
        }
      }

      return (
        <Input
          maxLength={maxLength}
          size={size}
          allowClear={allowClear}
          {...field}
          {...props}
          ref={ref}
        />
      );
    };

    return (
      <div className={`input-field ${className} ${vertical ? "vertical" : ""}`}>
        <Form.Item
          {...(!vertical ? formItemLayout : {})}
          label={label}
          validateStatus={isError ? "error" : ""}
          help={
            <>
              <div className="helper-wrapper">
                {isError && (
                  <div className="error-text">
                    <InfoCircleOutlined className="info-icon" type="info-circle" />
                    {`${meta.error || message}`}
                  </div>
                )}
                {/* {maxLength && <div className="max-length-text">{_get(field.value, 'length', 0)}/{maxLength}</div>} */}
              </div>
              <p className="explain">{description}</p>
            </>
          }
        >
          {renderInput()}
        </Form.Item>
      </div>
    );
  }
);

TextInput.displayName = "TextInput";
TextInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  inputType: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  message: PropTypes.string,
  description: PropTypes.string
};

export default memo(TextInput);
