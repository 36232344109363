import { Outlet } from "react-router-dom";
import React from "react";

import GuestLayout from "components/Layout/guest";

const Guest = () => {
  return (
    <>
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    </>
  );
};
export default Guest;
