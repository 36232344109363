import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import i18n from "i18n";
import { useAuth0 } from "@auth0/auth0-react";
import { LockOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import DashboardLayout from "components/Layout";
import { toggleCollapseMenu } from "providers/GeneralProvider/actions";
import { logoutRequest } from "providers/AuthProvider/actions";
import { makeSelectCollapseMenu } from "providers/GeneralProvider/selectors";
import { makeSelectUserLogged } from "providers/AuthProvider/selectors";
import {
  getProductListRequest,
  getRoleListRequest,
  setEnableSelectRole
} from "providers/AppProvider/actions";
import { checkAuth0InfoRequest } from "providers/AuthProvider/actions";
import constants from "utils/constants";
import helpers from "utils/helpers";

const Dashboard = () => {
  const collapseMenu = useSelector(makeSelectCollapseMenu());
  const userLogged = useSelector(makeSelectUserLogged());
  const { roles, products, enableSelectRoles } = useSelector((state) => state.app);
  const { userServiceDomain } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleMenu = (data) => {
    dispatch(toggleCollapseMenu(data));
  };
  const { logout } = useAuth0();

  useEffect(() => {
    if (_.isEmpty(userLogged)) {
      dispatch(checkAuth0InfoRequest());
    }

    if (!_.isEmpty(userLogged)) {
      if (!_.get(roles, "[0]")) {
        dispatch(getRoleListRequest());
      } else if (!_.get(enableSelectRoles, "[0]")) {
        const enableSelectRoles = _.filter(
          roles,
          (item) =>
            item.role_code !== constants.ROLE.CODE.SUPER_ADMIN &&
            item.role_code !==
              _.get(userServiceDomain, `${helpers.getProductCode()}.role.role_code`)
        );
        dispatch(setEnableSelectRole(enableSelectRoles));
      }

      if (!_.get(products, "[0]")) {
        dispatch(getProductListRequest());
      }
    }
  }, [roles, products, userLogged]);

  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          icon={<SettingOutlined />}
          onClick={() => {
            navigate("/my-profile");
          }}
        >
          {i18n.t("menuAccount.profile")}
        </Menu.Item>
        <Menu.Item
          icon={<LockOutlined />}
          onClick={() => {
            dispatch(logoutRequest({ auth0Logout: logout }));
          }}
        >
          {i18n.t("menuAccount.logOut")}
        </Menu.Item>
      </Menu>
    );
  };

  const menuSuperAdmin = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            navigate("/register-super-admin");
          }}
        >
          {i18n.t("menuAccount.superAdminRegister")}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            navigate("/register-product");
          }}
        >
          {i18n.t("menuAccount.productRegister")}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            navigate("/setting");
          }}
        >
          {i18n.t("menuAccount.displaySetting")}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <DashboardLayout
        menu={menu}
        menuSuperAdmin={menuSuperAdmin}
        userLogged={userLogged}
        collapseMenu={collapseMenu}
        toggleCollapseMenu={toggleMenu}
      >
        <Outlet />
      </DashboardLayout>
    </>
  );
};
export default Dashboard;
