import React from "react";
import PropTypes from "prop-types";
import { Bar } from "@ant-design/charts";
import _ from "lodash";
import helpers from "utils/helpers";

const RenderBarChart = (props) => {
  const { data } = props;
  const dataChart = [];
  const hightLight = ["loyal customers", "loyal servicers", "champions"];

  let typeSum = 0;
  if (!_.isEmpty(data)) {
    _.forEach(data, function (value, key) {
      typeSum = typeSum + value.total;
      dataChart.push({
        type: value.class_name,
        total: value.total
      });
    });
  }

  const chartConfig = {
    data: dataChart,
    xField: "total",
    yField: "type",
    label: {
      position: "right",
      offset: 4,
      style: {
        fill: "black"
      },
      content: (item) => {
        const distributionPercentage = helpers.calculatePercentage(item.total, typeSum) + "%";
        return item.total + " (" + distributionPercentage + ")";
      }
    },
    seriesField: "type",
    colorField: "type",
    color: ({ type }) => {
      if (hightLight.includes(type)) {
        return "#b22222";
      }
      return "#c0c0c0";
    },
    legend: false,
    meta: {
      type: { alias: "type" },
      total: { alias: "total" }
    },
    xAxis: {
      visible: false,
      grid: null
    },
    yAxis: {
      label: {
        style: {
          fill: "black"
        }
      },
      line: null,
      tickLine: null
    },
    tooltip: {
      formatter: (item) => {
        const percent = helpers.calculatePercentage(item.total, typeSum);
        const value = item.total + ` (${percent}%)`;
        return { name: item.type, value: value };
      }
    }
  };

  return <>{!_.isEmpty(dataChart) && <Bar {...chartConfig} />}</>;
};

RenderBarChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default RenderBarChart;
