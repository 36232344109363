import { DatePicker, Input, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import constants from "utils/constants";
import "./style.less";
const { Option } = Select;

const TableField = (input) => {
  const {
    fieldName,
    searchType,
    selectItems,
    isMultiple = true,
    submit,
    dispatch,
    time,
    onChange,
    defaultValue
  } = input;

  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (time) {
      setValue("");
    }
  }, [time]);

  const handleChange = (val, type, fieldName) => {
    setValue(val);
    if (onChange) {
      onChange(val, type, fieldName);
      if (_.isEmpty(val)) submit({ dispatch });
    }
  };

  if (searchType === constants.SEARCH_TYPE.SELECT) {
    return (
      <Select
        value={value ? value : undefined}
        mode={isMultiple ? "multiple" : ""}
        allowClear={true}
        style={{ width: "100%" }}
        suffixIcon={false}
        bordered={false}
        listHeight={150}
        onChange={(value) => {
          const val = value?.length ? value : undefined;
          handleChange(val, "filter", fieldName);
        }}
      >
        {_.map(selectItems, (item) => (
          <Option key={item.key || item.value}>{`${item.name}`}</Option>
        ))}
      </Select>
    );
  }

  if (searchType === constants.SEARCH_TYPE.DATE_PICKER) {
    return (
      <DatePicker
        value={value ? moment(value) : value}
        placeholder=""
        format={fieldName == "birthday" ? constants.FORMAT.MONTH_VIEW : constants.FORMAT.DATE_VIEW}
        picker={fieldName == "birthday" ? "month" : ""}
        suffixIcon={false}
        bordered={false}
        onChange={(date) => {
          const format =
            fieldName === "birthday"
              ? constants.FORMAT.SINGLE_SEND_MONTH
              : constants.FORMAT.SINGLE_SEND_DATE;
          const val = !date ? null : moment(date).format(format);
          handleChange(val, "search", fieldName);
        }}
      />
    );
  }

  if (searchType === constants.SEARCH_TYPE.DATETIME_PICKER) {
    return (
      <DatePicker
        value={value ? moment(value) : value}
        placeholder=""
        showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
        format={
          fieldName == "birthday" ? constants.FORMAT.MONTH_VIEW : constants.FORMAT.DATETIME_FORMAT
        }
        picker={fieldName == "birthday" ? "month" : ""}
        suffixIcon={false}
        bordered={false}
        onChange={(date) => {
          const format =
            fieldName === "birthday"
              ? constants.FORMAT.SINGLE_SEND_MONTH
              : constants.FORMAT.SINGLE_SEND_DATE;
          const val = !date ? null : moment(date).format(format);
          handleChange(val, "search", fieldName);
        }}
      />
    );
  }

  return (
    <Input
      value={value}
      allowClear
      bordered={false}
      onChange={(e) => handleChange(e.target.value, "search", fieldName)}
      onPressEnter={(e) => {
        submit({ dispatch });
      }}
    />
  );
};

export default TableField;
