import React from "react";
import PropTypes from "prop-types";
import { Column } from "@ant-design/charts";
import _ from "lodash";

const RenderSegmentationColumnByRF = (props) => {
  const { data, height } = props;
  const dataChart = [];

  if (!_.isEmpty(data)) {
    _.forEach(data, function (value, key) {
      dataChart.push({
        monetary: value.monetary.toString(),
        total: value.total
      });
    });
  }

  const chartConfig = {
    data: dataChart,
    xField: "monetary",
    yField: "total",
    label: {
      position: "top",
      offset: 0,
      style: {
        fill: "black",
        opacity: 0.9
      }
    },
    color: (_ref) => {
      const maxItem = _.maxBy(dataChart, "total");
      if (_ref.monetary === maxItem.monetary) {
        return "#C2001B";
      }
      return "#C0C0C0";
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    yAxis: {
      visible: false,
      grid: null
    },
    meta: {
      monetary: { alias: "monetary" },
      total: { alias: "total" }
    },
    height: height,
    padding: [20, 12]
  };
  return <>{!_.isEmpty(dataChart) && <Column {...chartConfig} />}</>;
};

RenderSegmentationColumnByRF.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number
};

RenderSegmentationColumnByRF.defaultProps = {
  height: 400
};

export default RenderSegmentationColumnByRF;
