import { put, takeLeading, call } from "redux-saga/effects";
import _ from "lodash";
import { notification } from "antd";
import moment from "moment";
import i18n from "i18n";

import helpers from "utils/helpers";
import constants from "utils/constants";
import request from "utils/api";
import { safe } from "providers/GeneralProvider/saga";
import {
  GET_TARGER_LIST_TEMPLATE_REQUEST,
  RUN_TARGET_TEMPLATE_REQUEST,
  GET_FILTER_VALUE_REQUEST,
  EXPORT_REQUEST,
  SAVE_TARGET_REQUEST,
  GET_TARGET_LIST_SPOOLED_REQUEST,
  GET_TARGET_LIST_SPOOL_LOG_REQUEST,
  EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST,
  EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST,
  CREATE_NEW_SENDER_REQUEST,
  GET_SENDER_LIST_REQUEST,
  DELETE_SENDER_REQUEST,
  CREATE_SINGLE_SEND_REQUEST,
  GET_SENDER_SELECT_LIST_REQUEST,
  GET_SINGLE_SEND_LIST_REQUEST,
  DELETE_SINGLE_SEND_REQUEST,
  CREATE_EMAIL_TEMPLATE_REQUEST,
  GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  DELETE_EMAIL_TEMPLATE_REQUEST,
  GET_DETAIL_EMAIL_TEMPLATE_REQUEST,
  GET_SEND_SCHEDULE_LIST_REQUEST,
  CREATE_SEND_SCHEDULE_REQUEST,
  GET_DETAIL_SEND_SCHEDULE_REQUEST,
  UPDATE_STATUS_SEND_SCHEDULE_REQUEST
} from "./constants";
import {
  error,
  getTargetListTemplateSuccess,
  getTargetListTemplateFailed,
  runTargetTemplateSuccess,
  runTargetTemplateFailed,
  getFilterValueSuccess,
  saveTargetSuccess,
  saveTargetFailed,
  exportSuccess,
  exportFailed,
  getTargetListSpoolLogSuccess,
  getTargetListSpooledSuccess,
  getTargetListSpooledFailed,
  exportTargetLogDetailSuccess,
  exportTargetLogDetailFailed,
  exportTargetLogListSuccess,
  exportTargetLogListFailed,
  createNewSenderSuccess,
  createNewSenderFailed,
  getSenderListSuccess,
  getSenderListFailed,
  deleteSenderSuccess,
  createSingleSendSuccess,
  createSingleSendFailed,
  getSenderSelectListSuccess,
  getSingleSendListSuccess,
  getSingleSendListFailed,
  deleteSingleSendSuccess,
  deleteSingleSendFailed,
  createEmailTemplateSuccess,
  createEmailTemplateFailed,
  getTargetListEmailTemplateSuccess,
  updateEmailTemplateSuccess,
  getTargetListEmailTemplateRequest,
  cancelLoadingTableEmailTemplate,
  getDetailEmailTemplateSuccess,
  getCopyEmailTemplateSuccess,
  getEditEmailTemplateSuccess,
  getSendScheduleSuccess,
  createSendScheduleSuccess,
  getCopySendScheduleSuccess,
  getSendScheduleRequest,
  getDetailSendScheduleSuccess,
  createSendScheduleFailed,
  getEditEmailTemplateScheduleSuccess
} from "./actions";

function* onError() {
  yield put(error());
}

export function* handleGetTargetListTemplate(input = {}) {
  const isCustomer = _.get(input, "payload.isCustomer");
  const result = yield call(request, "customer/spool/list", "get", null, {
    params: {
      target_type: isCustomer ? "customer" : "servicer"
    }
  });
  yield put(getTargetListTemplateSuccess(_.get(result, "payload")));
}

function* onGetTargetListTemplateFailed() {
  yield put(getTargetListTemplateFailed());
}

export function* handleRunTargetTemplate(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload.requestData"));
  _.set(requestBody, "target_list_spool_template_id");
  _.set(requestBody, "procedure_name_alias");

  _.forEach(_.get(requestBody, "conditions.properties", []), (item) => {
    if (item.type === constants.DATA_TYPE.DATETIME) {
      item.val = moment(item.val).format(constants.FORMAT.TARGER_SPOOL_DATETIME);
    }
  });

  if (!_.get(requestBody, "conditions.sorts")) {
    requestBody.conditions.sorts = [];
  }

  if (!_.find(requestBody.conditions.sorts, (item) => item.field === "id")) {
    requestBody.conditions.sorts.push({
      field: "original_id",
      type: "desc"
    });
  }

  if (requestBody.big_query_table_id) {
    delete requestBody.target_list_spool_template_id;
    delete requestBody.procedure_name_alias;
    delete requestBody.procedure_name;
  }
  const result = yield call(request, "customer/spool/show", "post", requestBody);
  const filterFields = _.filter(
    _.get(result, "payload.allowed_filter_fields", []),
    (item) => item !== "email"
  );
  if (_.get(input, "payload.isRunSearch") && filterFields.length) {
    _.set(requestBody, "conditions.unique", filterFields);
    const uniqueFieldResult = yield call(
      request,
      "customer/spool/unique-field",
      "post",
      requestBody
    );
    yield put(getFilterValueSuccess(_.get(uniqueFieldResult, "payload.data")));
  }

  yield put(runTargetTemplateSuccess(_.get(result, "payload")));
}

function* onRunTargetTemplateFailed() {
  yield put(runTargetTemplateFailed());
}

export function* handleGetFilterValue(input) {
  const result = yield call(
    request,
    "customer/spool/unique-field",
    "post",
    _.get(input, "payload")
  );
  yield put(getFilterValueSuccess(_.get(result, "payload.data")));
}

export function* handleExport(input) {
  const requestBody = { ..._.get(input, "payload", {}) };
  const result = yield call(
    request,
    "customer/spool/export",
    "post",
    requestBody,
    { responseType: "arraybuffer" },
    true
  );
  var data = new Blob([_.get(result, "data")], { type: _.get(result, "headers.content-type") });
  var csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute(
    "download",
    _.get(result, "headers.content-disposition", "").split("filename=")[1]
  );
  tempLink.click();

  yield put(exportSuccess());
}

export function* onHandleExportError() {
  yield put(exportFailed());
}

export function* handleSaveTarget(input) {
  const requestBody = { ..._.get(input, "payload", {}) };
  if (requestBody.big_query_table_id) {
    delete requestBody.procedure_name;
    delete requestBody.target_list_spool_template_id;
  }
  const result = yield call(request, "customer/spool/save", "post", requestBody);
  yield put(saveTargetSuccess(_.get(result, "payload")));
  if (!_.isEmpty(_.get(result, "payload"))) {
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  } else {
    yield put(saveTargetFailed({ error: i18n.t("message.errorSaveTargetNoData") }));
    notification.error({
      message: i18n.t("message.errorSaveTargetNoData"),
      duration: 2
    });
  }
}

export function* handleSaveTargetError() {
  yield put(saveTargetFailed());
}

export function* handleGetTargetListSpoolLog(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }
  if (_.isEmpty(requestBody?.sendgrid_schedule_id)) {
    delete requestBody.sendgrid_schedule_id;
  }
  requestBody.sorts.push({
    field: "updated_at",
    type: "desc"
  });
  const result = yield call(request, "/spool/log/list", "post", requestBody);
  yield put(getTargetListSpoolLogSuccess(_.get(result, "payload")));
}

export function* handleGetTargetListSpooled(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));

  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: "original_id",
    type: "asc"
  });

  const result = yield call(request, "/spool/log/detail", "post", requestBody);
  yield put(getTargetListSpooledSuccess(_.get(result, "payload")));
}
export function* handleGetSendScheduleList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: "updated_at",
    type: "desc"
  });
  const result = yield call(request, "/sendgrid/schedule/list", "post", requestBody);
  yield put(getSendScheduleSuccess(_.get(result, "payload")));
}

export function* handleGetTargetListEmailTemplate(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: "updated_at",
    type: "desc"
  });
  const result = yield call(request, "/mail/template/list", "post", requestBody);
  yield put(getTargetListEmailTemplateSuccess(_.get(result, "payload")));
}

export function* handleGetDetailEmailTemplate(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  const result = yield call(request, "/mail/template/detail", "get", null, {
    params: { id: requestBody.id }
  });

  switch (requestBody?.type) {
    case "copy":
      yield put(getCopyEmailTemplateSuccess(_.get(result, "payload")));
      break;
    case "detail":
      yield put(getDetailEmailTemplateSuccess(_.get(result, "payload")));
      break;
    case "edit":
      yield put(getEditEmailTemplateSuccess(_.get(result, "payload")));
      break;
    case "detail-schedule":
      yield put(getEditEmailTemplateScheduleSuccess(_.get(result, "payload")));
      break;
  }
}
export function* handleGetDetailSendSchedule(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  const result = yield call(request, "/sendgrid/schedule/detail", "get", null, {
    params: { id: requestBody.id }
  });

  switch (requestBody?.type) {
    case "copy":
      yield put(getCopySendScheduleSuccess(_.get(result, "payload")));
      break;
    case "detail":
      yield put(getDetailSendScheduleSuccess(_.get(result, "payload")));
      break;
  }
}
export function* onGetTargetListSpooledError() {
  yield put(getTargetListSpooledFailed());
}

export function* handleExportTargetLogList(input) {
  const requestBody = { ..._.get(input, "payload", {}) };

  const result = yield call(
    request,
    "/spool/log/export-list",
    "post",
    requestBody,
    { responseType: "arraybuffer" },
    true
  );
  var data = new Blob([_.get(result, "data")], { type: _.get(result, "headers.content-type") });
  var csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute(
    "download",
    _.get(result, "headers.content-disposition", "").split("filename=")[1]
  );
  tempLink.click();

  yield put(exportTargetLogListSuccess());
}

export function* onExportTargetLogListError() {
  yield put(exportTargetLogListFailed());
}

export function* handleExportTargetLogDetail(input) {
  const requestBody = { ..._.get(input, "payload", {}) };

  const result = yield call(
    request,
    "/spool/log/export-spooled",
    "post",
    requestBody,
    { responseType: "arraybuffer" },
    true
  );
  var data = new Blob([_.get(result, "data")], { type: _.get(result, "headers.content-type") });
  var csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute(
    "download",
    _.get(result, "headers.content-disposition", "").split("filename=")[1]
  );
  tempLink.click();

  yield put(exportTargetLogDetailSuccess());
}

export function* onExportTargetLogDetailError() {
  yield put(exportTargetLogDetailFailed());
}

export function* handleCreateNewSender(input) {
  const requestBody = { ..._.get(input, "payload", {}) };

  const result = yield call(request, "/sendgrid/create_sender", "post", requestBody);
  if (_.get(result, "payload.id")) {
    yield put(createNewSenderSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  } else if (parseInt(_.get(result, "code", 0)) === 400) {
    yield put(createNewSenderFailed(_.get(result, "payload", {})));
  }
}

export function* onCreateNewSenderError() {
  yield put(createNewSenderFailed());
}

export function* handleGetSenderList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));

  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: "updated_at",
    type: "desc"
  });

  const result = yield call(request, "/sendgrid/list_sender", "post", requestBody);
  yield put(getSenderListSuccess(_.get(result, "payload")));
}

export function* onGetSenderListError() {
  yield put(getSenderListFailed());
}

export function* handleDeleteSender(input) {
  const requestBody = _.get(input, "payload", {});

  const result = yield call(request, "/sendgrid/delete_senders", "delete", null, {
    data: requestBody
  });
  if (parseInt(_.get(result, "code", 0)) === 200) {
    yield put(deleteSenderSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.deleteSuccess"),
      duration: 2
    });
  }
}

export function* handleCreateSingleSend(input) {
  const requestBody = _.get(input, "payload", {});

  const result = yield call(request, "/sendgrid/create_single_send", "post", requestBody);
  if (_.get(result, "payload.id")) {
    yield put(createSingleSendSuccess(_.get(result, "payload")));
  } else if (parseInt(_.get(result, "code", 0)) === 400) {
    yield put(createSingleSendFailed(_.get(result, "payload", {})));
    if (_.isEmpty(result.payload)) {
      notification.error({
        message: i18n.t("message.badRequest"),
        duration: 2
      });
    }
  }
}

export function* handleCreateEmailTemplate(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  requestBody.is_private = 1;

  const result = yield call(request, "/mail/template/create", "post", requestBody);
  if (_.get(result, "payload.id")) {
    yield put(createEmailTemplateSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  } else {
    yield put(createEmailTemplateFailed(_.get(result, "payload", {})));
    if (_.isEmpty(result.payload)) {
      notification.error({
        message: i18n.t("message.badRequest"),
        duration: 2
      });
    }
  }
}
export function* handleCreateSendSchedule(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));

  const result = yield call(request, "/sendgrid/schedule/create", "post", requestBody);
  if (_.get(result, "payload.id")) {
    yield put(createSendScheduleSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  } else {
    yield put(createSendScheduleFailed(_.get(result, "payload", {})));
    if (_.isEmpty(result.payload)) {
      notification.error({
        message: result.message,
        duration: 2
      });
    }
  }
}
export function* onCreateEmailTemplateError() {
  yield put(createEmailTemplateFailed());
}
export function* onCreateSendScheduleError() {
  yield put(createSendScheduleFailed());
}
export function* onEmailTemplateDeleteError() {
  yield put(cancelLoadingTableEmailTemplate());
}
export function* onGetDetailEmailTemplateError() {
  yield put(cancelLoadingTableEmailTemplate());
}
export function* handleUpdateEmailTemplate(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));
  requestBody.is_private = 1;
  //There isn't any coupon which was select, set coupon_original_is is null
  //Because if client doesn't send the param name coupon_original_id to server, the api will skip to update for the column coupon_original_id
  if (!_.get(requestBody, "coupon_original_id")) {
    requestBody.coupon_original_id = "";
  }
  const result = yield call(request, "/mail/template/update", "post", requestBody);

  if (_.get(result, "payload.id")) {
    yield put(updateEmailTemplateSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.saveSuccess"),
      duration: 2
    });
  } else {
    yield put(createEmailTemplateFailed(_.get(result, "payload", {})));
    if (_.isEmpty(result.payload)) {
      notification.error({
        message: i18n.t("message.badRequest"),
        duration: 2
      });
    }
  }
}

export function* handleEmailTemplateDelete(input) {
  const requestBody = {
    ..._.get(input, "payload.emailDeleteInfoPayload"),
    product: helpers.getProductId()
  };

  const result = yield call(request, "mail/template/delete", "delete", null, { data: requestBody });
  if (_.get(result, "payload")) {
    helpers.notify(i18n.t("content.targetListSpool.mailTemplateTab.emailTemplateDeleteSuccess"));
    yield put(getTargetListEmailTemplateRequest(_.get(input, "payload.emailListPayload")));
  }
  yield put(cancelLoadingTableEmailTemplate());
}
export function* handleUpdateStatusSendSchedule(input) {
  const requestBody = {
    ..._.get(input, "payload.sendScheduleInfoPayload")
  };
  const dataUpdate = {};
  dataUpdate.id = requestBody.id;

  if (requestBody.executed == 1) {
    dataUpdate.status = 0; //stop
  } else {
    dataUpdate.status = 2; //delete
  }
  const result = yield call(request, "/sendgrid/schedule/updateStatus", "post", null, {
    data: dataUpdate
  });
  if (_.get(result, "payload")) {
    if (requestBody.executed == 1) {
      helpers.notify(i18n.t("content.targetListSpool.sendSchedule.sendScheduleStopSuccess"));
    } else {
      helpers.notify(i18n.t("content.targetListSpool.sendSchedule.sendScheduleDeleteSuccess"));
    }
    yield put(getSendScheduleRequest(_.get(input, "payload.sendScheduleListPayload")));
  }
  yield put(cancelLoadingTableEmailTemplate());
}

export function* onCreateSingleSendError() {
  yield put(createSingleSendFailed());
}

export function* handleGetSenderSelectList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));

  requestBody.sorts = [
    { field: "updated_at", type: "desc" },
    { field: "nickname", type: "desc" }
  ];

  const result = yield call(request, "/sendgrid/senders", "post", requestBody);
  yield put(getSenderSelectListSuccess(_.get(result, "payload", [])));
}

export function* handleGetSingleSendList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));

  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: "updated_at",
    type: "desc"
  });

  const result = yield call(request, "/sendgrid/list_single_send", "post", requestBody);
  yield put(getSingleSendListSuccess(_.get(result, "payload")));
}

export function* onGetSingleSendListError() {
  yield put(getSingleSendListFailed());
}

export function* handleDeleteSingleSend(input) {
  const data = _.get(input, "payload", {});

  const result = yield call(
    request,
    `/sendgrid/delete_single_send/${data.single_send_id}`,
    "delete"
  );
  if (parseInt(_.get(result, "code", 0)) === 204) {
    yield put(deleteSingleSendSuccess(_.get(result, "payload")));
    notification.success({
      message: i18n.t("message.deleteSuccess"),
      duration: 2
    });
  } else {
    notification.error({
      message: i18n.t("message.badRequest"),
      duration: 2
    });
    yield put(deleteSingleSendFailed(_.get(result, "payload")));
  }
}

export default function* watchAuth() {
  yield takeLeading(
    GET_TARGER_LIST_TEMPLATE_REQUEST,
    safe(handleGetTargetListTemplate, onGetTargetListTemplateFailed)
  );

  yield takeLeading(
    RUN_TARGET_TEMPLATE_REQUEST,
    safe(handleRunTargetTemplate, onRunTargetTemplateFailed)
  );

  yield takeLeading(GET_FILTER_VALUE_REQUEST, safe(handleGetFilterValue));

  yield takeLeading(EXPORT_REQUEST, safe(handleExport, onHandleExportError));

  yield takeLeading(SAVE_TARGET_REQUEST, safe(handleSaveTarget, handleSaveTargetError));

  yield takeLeading(GET_TARGET_LIST_SPOOL_LOG_REQUEST, safe(handleGetTargetListSpoolLog, onError));

  yield takeLeading(
    GET_TARGET_LIST_SPOOLED_REQUEST,
    safe(handleGetTargetListSpooled, onGetTargetListSpooledError)
  );

  yield takeLeading(GET_SEND_SCHEDULE_LIST_REQUEST, safe(handleGetSendScheduleList));

  yield takeLeading(GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST, safe(handleGetTargetListEmailTemplate));
  yield takeLeading(
    GET_DETAIL_EMAIL_TEMPLATE_REQUEST,
    safe(handleGetDetailEmailTemplate, onGetDetailEmailTemplateError)
  );
  yield takeLeading(
    GET_DETAIL_SEND_SCHEDULE_REQUEST,
    safe(handleGetDetailSendSchedule, onGetDetailEmailTemplateError)
  );
  yield takeLeading(
    EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST,
    safe(handleExportTargetLogList, onExportTargetLogListError)
  );

  yield takeLeading(
    EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST,
    safe(handleExportTargetLogDetail, onExportTargetLogDetailError)
  );

  yield takeLeading(CREATE_NEW_SENDER_REQUEST, safe(handleCreateNewSender, onCreateNewSenderError));

  yield takeLeading(GET_SENDER_LIST_REQUEST, safe(handleGetSenderList, onGetSenderListError));

  yield takeLeading(DELETE_SENDER_REQUEST, safe(handleDeleteSender));

  yield takeLeading(
    CREATE_SINGLE_SEND_REQUEST,
    safe(handleCreateSingleSend, onCreateSingleSendError)
  );

  yield takeLeading(GET_SENDER_SELECT_LIST_REQUEST, safe(handleGetSenderSelectList));
  yield takeLeading(
    CREATE_EMAIL_TEMPLATE_REQUEST,
    safe(handleCreateEmailTemplate, onCreateEmailTemplateError)
  );
  yield takeLeading(
    UPDATE_EMAIL_TEMPLATE_REQUEST,
    safe(handleUpdateEmailTemplate, onCreateEmailTemplateError)
  );
  yield takeLeading(
    DELETE_EMAIL_TEMPLATE_REQUEST,
    safe(handleEmailTemplateDelete, onEmailTemplateDeleteError)
  );
  yield takeLeading(
    CREATE_SEND_SCHEDULE_REQUEST,
    safe(handleCreateSendSchedule, onCreateSendScheduleError())
  );
  yield takeLeading(
    UPDATE_STATUS_SEND_SCHEDULE_REQUEST,
    safe(handleUpdateStatusSendSchedule, onCreateSendScheduleError)
  );

  yield takeLeading(
    GET_SINGLE_SEND_LIST_REQUEST,
    safe(handleGetSingleSendList, onGetSingleSendListError)
  );

  yield takeLeading(DELETE_SINGLE_SEND_REQUEST, safe(handleDeleteSingleSend));
}
