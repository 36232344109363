
export const USER_REGISTER_REQUEST = '@UserProvider/USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = '@UserProvider/USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILED = '@UserProvider/USER_REGISTER_FAILED';

export const GET_USER_LIST_REQUEST = '@UserProvider/GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = '@UserProvider/GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILED = '@UserProvider/GET_USER_LIST_FAILED';

export const USER_APPROVAL_REQUEST = '@UserProvider/USER_APPROVAL_REQUEST';
export const USER_APPROVAL_SUCCESS = '@UserProvider/USER_APPROVAL_SUCCESS';
export const USER_APPROVAL_FAILED = '@UserProvider/USER_APPROVAL_FAILED';

export const USER_DELETE_REQUEST = '@UserProvider/USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = '@UserProvider/USER_DELETE_SUCCESS';
export const USER_DELETE_FAILED = '@UserProvider/USER_DELETE_FAILED';

export const USER_UPDATE_REQUEST = '@UserProvider/USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = '@UserProvider/USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILED = '@UserProvider/USER_UPDATE_FAILED';

export const USER_INVITE_REQUEST = '@UserProvider/USER_INVITE_REQUEST';
export const USER_INVITE_SUCCESS = '@UserProvider/USER_INVITE_SUCCESS';
export const USER_INVITE_FAILED = '@UserProvider/USER_INVITE_FAILED';

export const ACCEPT_INVITE_REQUEST = '@UserProvider/ACCEPT_INVITE_REQUEST';
export const ACCEPT_INVITE_SUCCESS = '@UserProvider/ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILED = '@UserProvider/ACCEPT_INVITE_FAILED';

export const GET_USER_PROFILE_REQUEST = '@UserProvider/GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = '@UserProvider/GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILED = '@UserProvider/GET_USER_PROFILE_FAILED';

export const USER_PROFILE_UPDATE_REQUEST = '@UserProvider/USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = '@UserProvider/USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILED = '@UserProvider/USER_PROFILE_UPDATE_FAILED';

export const GET_USER_SETTING_REQUEST = '@UserProvider/GET_USER_SETTING_REQUEST';
export const GET_USER_SETTING_SUCCESS = '@UserProvider/GET_USER_SETTING_SUCCESS';
export const GET_USER_SETTING_FAIL = '@UserProvider/GET_USER_SETTING_FAIL';

export const USER_SETTING_UPDATE_REQUEST = '@UserProvider/USER_SETTING_UPDATE_REQUEST';
export const USER_SETTING_UPDATE_SUCCESS = '@UserProvider/USER_SETTING_UPDATE_SUCCESS';
export const USER_SETTING_UPDATE_FAILED = '@UserProvider/USER_SETTING_UPDATE_FAILED';

export const REGISTER_COUPON_SERVICE_REQUEST = '@UserProvider/REGISTER_COUPON_SERVICE_REQUEST';
export const REGISTER_COUPON_SERVICE_SUCCESS = '@UserProvider/REGISTER_COUPON_SERVICE_SUCCESS';
export const REGISTER_COUPON_SERVICE_FAILED = '@UserProvider/REGISTER_COUPON_SERVICE_FAILED';

export const REGISTER_COUPON_SERVICE_V1_REQUEST = '@UserProvider/REGISTER_COUPON_SERVICE_V1_REQUEST';
export const REGISTER_COUPON_SERVICE_V1_SUCCESS = '@UserProvider/REGISTER_COUPON_SERVICE_V1_SUCCESS';
export const REGISTER_COUPON_SERVICE_V1_FAILED = '@UserProvider/REGISTER_COUPON_SERVICE_V1_FAILED';

export const REGISTER_POINT_SERVICE_REQUEST = '@UserProvider/REGISTER_POINT_SERVICE_REQUEST';
export const REGISTER_POINT_SERVICE_SUCCESS = '@UserProvider/REGISTER_POINT_SERVICE_SUCCESS';
export const REGISTER_POINT_SERVICE_FAILED = '@UserProvider/REGISTER_POINT_SERVICE_FAILED';

export const REGISTER_PRODUCT_REQUEST = '@UserProvider/REGISTER_PRODUCT_REQUEST';
export const REGISTER_PRODUCT_SUCCESS = '@UserProvider/REGISTER_PRODUCT_SUCCESS';
export const REGISTER_PRODUCT_FAILED = '@UserProvider/REGISTER_PRODUCT_FAILED';

export const SUPER_ADMIN_REGISTER_REQUEST = '@UserProvider/SUPER_ADMIN_REGISTER_REQUEST';
export const SUPER_ADMIN_REGISTER_SUCCESS = '@UserProvider/SUPER_ADMIN_REGISTER_SUCCESS';
export const SUPER_ADMIN_REGISTER_FAILED = '@UserProvider/SUPER_ADMIN_REGISTER_FAILED';

export const RESET_REGISTER_PRODUCT = '@UserProvider/RESET_REGISTER_PRODUCT';
export const RESET_REGISTER_COUPON_SERVICE = '@UserProvider/RESET_REGISTER_COUPON_SERVICE';
export const RESET_REGISTER_COUPON_V1_SERVICE = '@UserProvider/RESET_REGISTER_COUPON_V1_SERVICE';
