import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILED,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  USER_APPROVAL_REQUEST,
  USER_APPROVAL_SUCCESS,
  USER_APPROVAL_FAILED,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  USER_INVITE_REQUEST,
  USER_INVITE_SUCCESS,
  USER_INVITE_FAILED,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILED,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILED,
  GET_USER_SETTING_REQUEST,
  GET_USER_SETTING_SUCCESS,
  USER_SETTING_UPDATE_REQUEST,
  USER_SETTING_UPDATE_SUCCESS,
  USER_SETTING_UPDATE_FAILED,
  GET_USER_SETTING_FAIL,
  REGISTER_COUPON_SERVICE_REQUEST,
  REGISTER_COUPON_SERVICE_SUCCESS,
  REGISTER_COUPON_SERVICE_FAILED,
  REGISTER_COUPON_SERVICE_V1_REQUEST,
  REGISTER_COUPON_SERVICE_V1_SUCCESS,
  REGISTER_COUPON_SERVICE_V1_FAILED,
  REGISTER_POINT_SERVICE_REQUEST,
  REGISTER_POINT_SERVICE_SUCCESS,
  REGISTER_POINT_SERVICE_FAILED,
  REGISTER_PRODUCT_REQUEST,
  REGISTER_PRODUCT_SUCCESS,
  REGISTER_PRODUCT_FAILED,
  SUPER_ADMIN_REGISTER_REQUEST,
  SUPER_ADMIN_REGISTER_SUCCESS,
  SUPER_ADMIN_REGISTER_FAILED,
  RESET_REGISTER_PRODUCT,
  RESET_REGISTER_COUPON_SERVICE,
  RESET_REGISTER_COUPON_V1_SERVICE
} from "./constants";

export const userRegisterRequest = (payload) => {
  return { type: USER_REGISTER_REQUEST, payload };
};

export const userRegisterSuccess = (payload) => {
  return { type: USER_REGISTER_SUCCESS, payload };
};

export const userRegisterFailed = () => {
  return { type: USER_REGISTER_FAILED };
};

export const getUserListRequest = (payload) => {
  return { type: GET_USER_LIST_REQUEST, payload };
};

export const getUserListSuccess = (payload) => {
  return { type: GET_USER_LIST_SUCCESS, payload };
};

export const getUserListFailed = () => {
  return { type: GET_USER_LIST_FAILED };
};

export const userApprovalRequest = (payload) => {
  return { type: USER_APPROVAL_REQUEST, payload };
};

export const userApprovalSuccess = (payload) => {
  return { type: USER_APPROVAL_SUCCESS, payload };
};

export const userApprovalFailed = () => {
  return { type: USER_APPROVAL_FAILED };
};

export const userDeleteRequest = (payload) => {
  return { type: USER_DELETE_REQUEST, payload };
};

export const userDeleteSuccess = (payload) => {
  return { type: USER_DELETE_SUCCESS, payload };
};

export const userDeleteFailed = () => {
  return { type: USER_DELETE_FAILED };
};

export const userUpdateRequest = (payload) => {
  return { type: USER_UPDATE_REQUEST, payload };
};

export const userUpdateSuccess = (payload) => {
  return { type: USER_UPDATE_SUCCESS, payload };
};

export const userUpdateFailed = () => {
  return { type: USER_UPDATE_FAILED };
};

export const userInviteRequest = (payload) => {
  return { type: USER_INVITE_REQUEST, payload };
};

export const userInviteSuccess = (payload) => {
  return { type: USER_INVITE_SUCCESS, payload };
};

export const userInviteFailed = (payload) => {
  return { type: USER_INVITE_FAILED, payload };
};

export const acceptInviteRequest = (payload) => {
  return { type: ACCEPT_INVITE_REQUEST, payload };
};

export const acceptInviteSuccess = (payload) => {
  return { type: ACCEPT_INVITE_SUCCESS, payload };
};

export const acceptInviteFailed = (payload) => {
  return { type: ACCEPT_INVITE_FAILED, payload };
};

export const getUserProfileRequest = () => {
  return { type: GET_USER_PROFILE_REQUEST };
};

export const getUserProfileSuccess = (payload) => {
  return { type: GET_USER_PROFILE_SUCCESS, payload };
};

export const getUserProfileFailed = (payload) => {
  return { type: GET_USER_PROFILE_FAILED, payload };
};

export const userProfileUpdateRequest = (payload) => {
  return { type: USER_PROFILE_UPDATE_REQUEST, payload };
};

export const userProfileUpdateSuccess = (payload) => {
  return { type: USER_PROFILE_UPDATE_SUCCESS, payload };
};

export const userProfileUpdateFailed = (payload) => {
  return { type: USER_PROFILE_UPDATE_FAILED, payload };
};

export const getUserSettingRequest = () => {
  return { type: GET_USER_SETTING_REQUEST };
};

export const getUserSettingSuccess = (payload) => {
  return { type: GET_USER_SETTING_SUCCESS, payload };
};

export const userSettingUpdateRequest = (payload) => {
  return { type: USER_SETTING_UPDATE_REQUEST, payload };
};

export const userSettingUpdateSuccess = (payload) => {
  return { type: USER_SETTING_UPDATE_SUCCESS, payload };
};

export const userSettingUpdateFailed = (payload) => {
  return { type: USER_SETTING_UPDATE_FAILED, payload };
};
export const getUserSettingFail = () => {
  return { type: GET_USER_SETTING_FAIL };
};

export const registerCouponServiceRequest = (payload) => {
  return { type: REGISTER_COUPON_SERVICE_REQUEST, payload };
};


export const registerCouponServiceSuccess = (payload) => {
  return { type: REGISTER_COUPON_SERVICE_SUCCESS, payload };
};

export const registerCouponServiceFailed = (payload) => {
  return { type: REGISTER_COUPON_SERVICE_FAILED, payload };
};

export const registerCouponServiceV1Request = (payload) => {
  return { type: REGISTER_COUPON_SERVICE_V1_REQUEST, payload };
};

export const registerCouponServiceV1Success = (payload) => {
  return { type: REGISTER_COUPON_SERVICE_V1_SUCCESS, payload };
};

export const registerCouponServiceV1Failed = (payload) => {
  return { type: REGISTER_COUPON_SERVICE_V1_FAILED, payload };
};


export const registerPointServiceRequest = (payload) => {
  return { type: REGISTER_POINT_SERVICE_REQUEST, payload };
};

export const registerPointServiceSuccess = (payload) => {
  return { type: REGISTER_POINT_SERVICE_SUCCESS, payload };
};

export const registerPointServiceFailed = (payload) => {
  return { type: REGISTER_POINT_SERVICE_FAILED, payload };
};

export const registerProductRequest = (payload) => {
  return { type: REGISTER_PRODUCT_REQUEST, payload };
};

export const registerProductSuccess = (payload) => {
  return { type: REGISTER_PRODUCT_SUCCESS, payload };
};

export const registerProductFailed = (payload) => {
  return { type: REGISTER_PRODUCT_FAILED, payload };
};
export const superAdminRegisterRequest = (payload) => {
  return { type: SUPER_ADMIN_REGISTER_REQUEST, payload };
};

export const superAdminRegisterSuccess = (payload) => {
  return { type: SUPER_ADMIN_REGISTER_SUCCESS, payload };
};

export const superAdminRegisterFailed = (payload) => {
  return { type: SUPER_ADMIN_REGISTER_FAILED, payload };
};

export const resetRegisterProduct = (payload) => {
  return { type: RESET_REGISTER_PRODUCT, payload };
};

export const resetRegisterCouponService = (payload) => {
  return { type: RESET_REGISTER_COUPON_SERVICE, payload };
};

export const resetRegisterCouponV1Service = (payload) => {
  return { type: RESET_REGISTER_COUPON_V1_SERVICE, payload };
};

