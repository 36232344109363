import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Select, Form, Empty } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import i18n from "i18n";
import "./style.less";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    sm: {
      span: 6
    }
  },
  wrapperCol: {
    sm: {
      span: 17,
      offset: 1
    }
  }
};

const SelectField = forwardRef(
  (
    {
      label,
      maxLength,
      size = "large",
      options = [],
      className = "",
      placeholder = i18n.t("placeholder.pleaseSelect"),
      event,
      vertical = true,
      errorMessageText = "",
      ...props
    },
    ref
  ) => {
    const [field, meta, helper] = useField(props);
    const { value } = field;
    const isError = (meta.touched && meta.error) || errorMessageText != "";
    const errorMessage = meta.touched && meta.error ? meta.error : errorMessageText;
    return (
      <div className={`select-field ${className} ${vertical ? "vertical" : ""}`}>
        <Form.Item
          {...(!vertical ? formItemLayout : {})}
          label={label}
          validateStatus={isError ? "error" : ""}
          help={
            <div className="helper-wrapper">
              <div className="error-text">
                {isError && (
                  <>
                    <InfoCircleOutlined className="info-icon" type="info-circle" />
                    {errorMessage}
                  </>
                )}
              </div>
            </div>
          }
        >
          <Select
            {...field}
            {...props}
            size={size}
            ref={ref}
            placeholder={placeholder}
            value={value === "" ? null : value}
            onDropdownVisibleChange={(visible) => {
              if (!visible) {
                helper.setTouched(true);
              } else {
                helper.setTouched(false);
              }
            }}
            onChange={(data) => {
              helper.setValue(data);
              if (event) {
                event(data);
              }
            }}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={i18n.t("placeholder.noData")}
              />
            }
          >
            <>
              {options.map((option) => (
                <Option value={option.key} key={option.key}>
                  {option.name}
                </Option>
              ))}
            </>
          </Select>
        </Form.Item>
      </div>
    );
  }
);

SelectField.displayName = "SelectField";

SelectField.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  inputType: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  options: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  event: PropTypes.func,
  vertical: PropTypes.bool,
  errorMessageText: PropTypes.string
};

export default memo(SelectField);
