import { number, object, string } from "yup";
import i18n from "i18n";

export const targetListCustomer = object().shape({
  procedure_name: string().trim().required(i18n.t("message.isRequired"))
  // conditions: object().shape({
  //   properties: array()
  //     .of(
  //       object().shape({
  //         op: string().trim().required(i18n.t('message.required')),
  //         val: string().trim().required(i18n.t('message.required'))
  //       })
  //     )
  // })
});
export const targetListCustomerView = object().shape({
  big_query_table_id: string().trim().required(i18n.t("message.isRequired"))
});
export const saveTargetTemplate = object().shape({
  name: string().trim().required(i18n.t("message.required.name"))
});

export const saveSender = object().shape({
  nickname: string().trim().required(i18n.t("message.isRequired")),
  from: object().shape({
    email: string()
      .trim()
      .required(i18n.t("message.isRequired"))
      .email(i18n.t("message.isInvalid")),
    name: string().trim().required(i18n.t("message.isRequired"))
  }),
  reply_to: object().shape({
    email: string().trim().required(i18n.t("message.isRequired")).email(i18n.t("message.isInvalid"))
  }),
  address: string().trim().required(i18n.t("message.isRequired")),
  city: string().trim().required(i18n.t("message.isRequired")),
  zip: string().trim().required(i18n.t("message.isRequired")),
  country: string().trim().required(i18n.t("message.isRequired"))
});

export const saveSingleSend = object().shape({
  single_send_name: string()
    .trim()
    .required(i18n.t("message.isRequired"))
    .test("maxbytes", i18n.t("message.maxsingleSendName"), (value) => {
      let size = 0;
      if (value) {
        size = new Blob([value]).size;
      }
      return size <= 80;
    }),
  subject: string().trim().required(i18n.t("message.isRequired")),
  sender_id: number().required(i18n.t("message.isRequired"))
});

export const saveTemplateEmail = object().shape({
  subject: string().trim().required(i18n.t("message.isRequired"))
});
export const saveSendSchedule = object().shape({
  sender_id: string().trim().required(i18n.t("message.isRequired")),
  subject: string().trim().required(i18n.t("message.isRequired")),
  mail_template_id: string().trim().required(i18n.t("message.isRequired"))
});
