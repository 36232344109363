export default {
  global: {
    appTitle: "マーケティングセンター",
    dashBoardTitle: "マーケティングダッシュボード",
    userManager: "ユーザー管理",
    userApproval: "ユーザー承認",
    userList: "ユーザーリスト",
    inviteUser: "ユーザー招待",
    targetListSpool: "ターゲットリスト抽出",
    customer: "カスタマー",
    servicer: "サービサー",
    targetListSpoolLog: "ターゲットリスト抽出のログ",
    aiSupportedTools: "AI関連機能",
    similarImageSearch: "類似画像検索",
    personalRecommendation: "リコメンド",
    customerSegmentation: "カスタマーセグメンテーション",
    servicerSegmentation: "サービサーセグメンテーション",
    campaignManagement: "キャンペーン管理",
    coupon: "クーポン",
    total: "合計",
    noResultFound: "結果が見つかりません！",
    day: "日",
    add: "追加",
    update: "更新",
    hogugu: "ホググ",
    nailie: "ネイリ",
    tanomel: "タノメル",
    hairlie: "ヘアリー",
    customerRecommendationMenu: "推奨情報のメニュー",
    customerRecommendation: "顧客推奨のサービサー",
    servicerMatchingScore: "サービサーマッチングスコアリング",
    menuMatchingScore: "メニューマッチングスコアリング",
    segmentation: {
      segment: "セグメント",
      description: "説明"
    },
    copyRight: {
      copyRightInfo: "Copyright © 2020 C2C Tech Hub. All Rights Reserved.",
      timezone: "本ウェブサイトの日付と時刻は日本標準時(UTC+9)です。"
    },
    point: "ポイント",
    pointConfig: "ポイント設定",
    pointConfigSecretKey: "プロダクトの秘密鍵",
    pointConfigExpiryDuration: "ポイントの有効期限",
    pointProductConfiguration: "プロダクト設定",
    pointExchangeRate: "ポイント為替レート",
    unRegistered: "未登録",
    pointTransferRate: "ポイント交換レート",
    searchTotalPoint: "ユーザーの合計ポイントの検索",
    pointHistorySearch: "ポイント履歴の検索",
    totalPointDaily: "デイリー合計ポイント",
    transferPointHistoryDaily: "毎日のポイント交換履歴",
    transferPointHistoryMonthly: "毎月のポイント交換履歴",
    invitationCoupon: "友達紹介クーポン",
    couponSetting: "クーポン設定",
    couponSettingSecretKey: "プロダクトの秘密鍵",
    imageSize: "画像サイズ",
    couponType: "クーポンタイプ",
    tag: "タグ",
    registerCouponV1: "クーポン V1 を登録する"
  },
  language: {
    en: "英語",
    ja: "日本語"
  },
  menuAccount: {
    logOut: "ログアウト",
    profile: "プロフィール",
    productRegister: "プロダクト登録",
    displaySetting: "表示設定",
    superAdminRegister: "スーパーアドミン登録"
  },
  action: {
    runSearch: "検索実行",
    save: "保存",
    saveAndActive: "保存＆アクティブ化",
    close: "閉じる",
    export: "エクスポート",
    search: "検索実行",
    submit: "登録",
    ok: "OK",
    cancel: "キャンセル",
    approval: "承認",
    delete: "削除",
    edit: "編集",
    send: "送信",
    update: "更新",
    create: "登録",
    reset: "リセット",
    yes: "はい",
    no: "いいえ",
    select: "選択",
    reviewAndSend: "プレビュー及び送信",
    reviewAndSave: "プレビュー及び保存",
    setting: "設定",
    design: "デザイン",
    copyTemplate: "テンプレートのコピー",
    import: "Import",
    copySchedule: "スケジュールのコピー",
    stop: "ストップ",
    log: "ログ",
    activate: "アクティブ",
    deActivate: "非アクティブ化",
    addCondition: "抽出要件追加",
    view: "ビュー",
    saveAndAplly: "保存＆適用",
    createInvitationCouponSetting: "作成",
    modalOk: "はい",
    modalCancel: "キャンセル"
  },
  placeholder: {
    noData: "空欄",
    pleaseEnter: "入力してください。",
    pleaseEnterInteger: "整数を入力してください。",
    pleaseSelect: "選択してください",
    searchToSelect: "選択して検索します",
    pleaseSelectMonth: "月を選択してください",
    couponCode: "20文字以内を入力してください。",
    couponTitle: "20文字以内を入力してください。",
    couponSelect: "クーポンのコードを選択してください",
    timeSelect: "HH:mm",
    tagName: "全角50文字まで"
  },
  content: {
    targetListSpool: {
      selectTargetListFromView: "ビューからのターゲットリストを選択する",
      selectTargetListFromProcedure: "ストアドプロシージャからのターゲットリストを選択する",
      customerTitle: "ターゲットリスト抽出 - カスタマー",
      servicerTitle: "ターゲットリスト抽出 - サービサー",
      logTitle: "ターゲットリスト抽出のログ",
      logTab1: "ターゲットリスト抽出のログ",
      logTab2: "抽出されたターゲットリスト",
      targetListSelectFiledTitle: "ターゲットリスト抽出テンプレート",
      confirmExport: "データをエクスポートしますか?",
      saveTitle: "新規作成ターゲットリスト抽出",
      templateName: "テンプレート名",
      param: "パラメータ",
      name: "名前",
      description: "説明",
      findNotFound: "結果が見つかりません！",
      logId: "ログID",
      logName: "ログ名",
      exporter: "輸出者",
      procedureName: "プロシージャ名",
      targetType: "ターゲットタイプ",
      targetList: "ターゲットリスト",
      exportDate: "エクスポート日付",
      totalEmail: "合計（メール）",
      totalSent: "合計（送信済）",
      totalSentFail: "合計（送信失敗）",
      totalOpened: "合計（開封）",
      totalClicked: "合計（クリック済）",
      userId: "ユーザーID",
      originalId: "オリジナルID",
      email: "メールアドレス",
      status: "ステータス",
      openMail: "メール開封",
      clickToLink: "リンクにクリック",
      confirmMoveToSendgridTab: "抽出したターゲットリストにメールをSendGridで送信しますか?",
      customerTabName: "カスタマー一覧",
      servicerTabName: "サービサー一覧",
      clickItems: {
        clicked: "クリック済み",
        notClick: "クリックしない"
      },
      openItems: {
        opened: "開封済み",
        notOpen: "開封しない"
      },
      senderTab: {
        title: "Sender設定",
        createNewSender: "新規登録",
        confirmDeleteSender: "このSender対象を削除しますか?",
        checkVerifyEmail:
          "メールアドレスのドメインを検証する必要があります。許可を確認するため、%{email}宛てに電子メールを送りました。続行するには、「Verify Sender Identity」ボタンをクリックしてください。",
        table: {
          no: "id",
          fromName: "From名",
          sender: "差出人",
          address: "住所",
          nickName: "一意な名前",
          verified: "検証ステータス",
          action: "アクション",
          from: "FROM",
          reply: "REPLY",
          verifyItems: {
            verified: "検証済み",
            notVerify: "検証しない"
          }
        },
        form: {
          title: "新規登録",
          fromName: "From名",
          fromEmailAddress: "Fromメールアドレス",
          replyTo: "Reply-Toメールアドレス",
          companyAddress: "会社住所",
          city: "市町村",
          zipCode: "郵便番号",
          country: "国",
          nickName: "一意な名前",
          explain: `CAN-SPAMやCASLなどのスパム対策法に準拠するために、送信するすべてのプロモーションメールには、
          実際の住所などの連絡先情報を含める必要があります。 この情報の入り変えるタグは、SendGridが提供するすべてのメールデザインのフッターにあります。`,

          fromEmailAddressExplain: `Fromメールアドレスのドメインが承認済みドメインと一致しない場合は、この送信者情報を使用する前に、
          検証する必要があります。この送信者情報を登録した後、登録されたメールアドレスに検証メールを送信します。`
        }
      },
      singleSendTab: {
        title: "Single Send設定",
        createNewSingleSend: "新規登録",
        confirmDeleteSingleSend: "このSingle sendは削除しますか?",
        errorBySenderDeleted: "選択したSender対象は削除されました。再度確認してください。",
        table: {
          no: "No",
          subject: "件名",
          createdBy: "作成者",
          action: "アクション"
        },
        form: {
          title: "新規登録",
          previewTitle: "Single Send詳細",
          singleSendName: "シングルセンド名",
          fromSender: "送信者",
          subject: "件名",
          sendTo: "Send Toメールアドレス",
          unsubscribeGroup: "配信停止グループ",
          confirmCloseSetting: "Single sendを作成するのはキャンセルしますか?"
        },
        finish: {
          title: "完了",
          text1:
            "マーケティングセンターは、%{email}でお客様にメールを送る処理を終了しました。要約は下記の通りです:",
          text2: "合計のSend To: %{sendToTal}",
          text3: "合計のUnsubscribe Groups: %{unsubscribeGroupTotal}",
          text4: "実行日は%{date} です。"
        }
      },
      mailTemplateTab: {
        form: {
          editTitle: "メールテンプレートの編集",
          previewTitle: "メールテンプレートのプレビュー",
          title: "メールテンプレート作成",
          confirmCloseSetting: "作成メールテンプレートをキャンセルしますか?"
        },
        tabTitle: "メールテンプレート",
        table: {
          no: "No",
          subject: "テンプレート名",
          action: "アクション",
          createdBy: "作成者"
        },
        subject: "テンプレート名",
        emailDetail: "メールテンプレートの詳細",
        confirmDelete: "このテンプレートを削除してもよろしいでしょうか。",
        emailTemplateDeleteSuccess: "削除成功しました。",
        createTemplate: "テンプレート作成",
        coupon: "クーポン",
        discountAmount: "価引き額",
        startFrom: "開始日付",
        endTo: "終了日付",
        couponInvalid:
          "選択済みのクーポンは削除されたか期限が切れたため表示できません。メール本文とクーポンの情報をもう一度ご確認ください。"
      },
      sendSchedule: {
        form: {
          target: "ターゲット",
          storeProcedureName: "ターゲットリスト抽出テンプレート",
          extractionCondition: "抽出条件",
          preview: "プレビュー",
          settingType: "ターゲットリスト",
          period: "有効期間",
          from: "から",
          to: "まで",
          settingSender: "送信者の設定",
          settingSingleSend: "シングルセンドの設定",
          previewTemplate: "プレビューテンプレート",
          listViews: "List Views"
        },
        table: {
          no: "No",
          targetType: "ターゲットタイプ",
          type: "プロシージャ名",
          extractionTemplate: "抽出テンプレート",
          sendingScheduleName: "送信スケジュールの名前",
          status: "ステータス",
          schedule: "スケジュール",
          subject: "件名",
          action: "アクション"
        },
        resultList: "ターゲットリスト",
        pageTitle: "送信スケジュールの一覧",
        tab1Title: "送信スケジュール",
        tab2Title: "送信スケジュールの作成",
        createSendingSchedule: "送信スケジュールの設定",
        createNewTemplate: "新規作成",
        confirmDelete: "このスケジュールを削除しますか ?",
        confirmStop: "このスケジュールを停止しますか ?",
        confirmDelete: "このスケジュールを削除しますか ?",
        confirmStop: "このスケジュールを停止しますか ?",
        createSendingSchedule: "送信スケジュールの設定",
        createNewTemplate: "新規作成",
        sendScheduleStopSuccess: "送信スケジュールを停止しました.",
        sendScheduleDeleteSuccess: "送信スケジュールを削除しました.",
        detail: {
          detailSendSchedule: "送信スケジュールの詳細",
          sendgridScheduleName: "送信スケジュールの名前",
          targetListSpoolCondition: "ターゲットリスト抽出条件",
          sendgridSchedule: "スケジュール",
          sender: "送信者",
          mailTemplate: "メールテンプレート",
          startFrom: "開始日付",
          endTo: "終了日付",
          ok: "OK"
        }
      },
      spAliasName: {
        customer_register_date: "登録日でユーザー一覧を取得します",
        v2_customer1: "ユーザーのステータスでユーザー一覧を取得します",
        customer_nailie_user_birthday: "誕生日でユーザー一覧を取得します",
        customer_nailie_user_never_booked: "予約したことがないユーザー一覧を取得します",
        customer_nailie_user_booked_once: "１回のみ予約したユーザー一覧を取得します",
        servicer_register_date: "登録日でユーザー一覧を取得します",
        v2_servicer1: "ユーザーのステータスでユーザー一覧を取得します",
        servicer_nailie_user_birthday: "誕生日でユーザー一覧を取得します",
        servicer_nailie_user_never_booked: "予約したことがないユーザー一覧を取得します",
        servicer_nailie_user_booked_once: "１回のみ予約したユーザー一覧を取得します"
      },
      spParamName: {
        register_date_from: "登録日から",
        register_date_to: "登録日まで",
        is_verified: "検証ステータス",
        status: "ステータス",
        birthday: "誕生日の月",
        register_date: "送信日から"
      },
      extraCondition: {
        and: "AND"
      }
    },
    user: {
      registerTitle: "ユーザー登録",
      listTitle: "ユーザーリスト",
      approvalTitle: "承認待ち一覧",
      userEditTitle: "ユーザー編集",
      inviteTitle: "ユーザー招待",
      profileTitle: "ユーザープロフィール",
      settingTitle: "表示設定",
      no: "No",
      fullname: "氏名",
      nickname: "ニックネーム",
      email: "メールアドレス",
      phone: "携帯番号",
      status: "ステータス",
      role: "ロール",
      product: "プロダクト",
      register: "登録",
      action: "アクション",
      emailIsWaitingApproved: "あなたのアカウントは承認待ちです!",
      anotherLoginAcc: "別のアカウントでログイン",
      confirmApproval: "このアカウントを承認しますか？",
      confirmDelete: "このアカウントを削除しますか？",
      accountUnavailable: "あなたのアカウントは利用できません！",
      invalidInviteLink: "招待リンクが無効です！",
      inviteLinkSent: "あなたのメールアドレスに招待メールを送信しましたので、ご確認ください!",
      userRegisterSuccess: "登録しました",
      userDeleteSuccess: "削除しました",
      userApprovalSuccess: "承認しました",
      userUpdateSuccess: "更新しました",
      userInviteSuccess: "招待しました",
      userDeleteByProduct: "あなたのアカウントが%{product}から削除されました",
      userSettingUpdateSuccess: "更新に成功しました。",
      productCode: "プロダクトコード",
      productName: "プロダクト名",
      registerCouponServiceTitle: "クーポンサービス登録",
      registerCouponServiceTitleV1: "クーポンサービスV1の登録",
      registerPointServiceTitle: "ポイントサービス登録",
      expiryDuration: "有効期限",
      extendRollingExpiryDateUsePoint: "ポイントを使用する時ローリング有効期限を延長する",
      extendRollingExpiryDateTransferPoint: "転送ポイントを使用する時ローリング有効期限を延長する",
      writerDBHost: "ライターDBホスト",
      readDBHost: "リードDBホスト",
      userName: "ユーザー名",
      passWord: "パスワード",
      databaseName: "データベース名",
      redisHost: "Redisホスト",
      registerProductTitle: "プロダクト登録",
      productNameEN: "プロダクト名 (En)",
      productNameJP: "プロダクト名 (Jp)",
      urlOfAdminSite: "管理サイトのURL",
      callbackURLOfAdminSite: "管理サイトのコールバックURL",
      registerSuperAdminTitle: "スーパーアドミン登録",
      clientId: "クライアントID",
      clientSecret: "クライアントシークレット",
      auth0Identifier: "Auth0 Api 識別子",
      appName: "アプリ名",
      appId: "アプリID",
      passphrase: "パスフレーズ",
      downloadPrivateKey: "秘密鍵をダウンロード",
      userId: "ユーザーID"
    },
    aiSupportedTools: {
      personalRecommendation: {
        customerTitle: "顧客推奨のサービサー",
        customerMenuTitle: "推奨情報のメニュー",
        menuMatchingScoreTitle: "メニューマッチングスコアリング",
        customerTab1: "推奨情報",
        customerTab2: "詳細",
        servicerMatchingScoreTitle: "サービサーマッチングスコアリング",
        table: {
          no: "No",
          customerId: "カスタマーID",
          fullName: "氏名",
          nickName: "ニックネーム",
          email: "メールアドレス",
          servicerId: "サービサーID",
          score: "スコア",
          rank: "ランク",
          area: "エリア",
          menuId: "メニューID",
          title: "タイトル",
          servicer: "サービサー",
          price: "価格",
          timeDuration: "期間"
        }
      },
      customerSegmentation: {
        customerSegmentationTitle: "カスタマーセグメンテーション",
        customerSegmentationDetailTitle: "詳細",
        customerSegmentationRFMDiagrams: "RFMダイアグラム",
        table: {
          no: "No",
          className: "クラス名",
          quantity: "量",
          customerName: "サービサー名",
          recency: "リーセンシー",
          frequency: "フリークエンシー",
          monetary: "モニタリー"
        }
      },
      servicerSegmentation: {
        servicerSegmentationTitle: "サービサーセグメンテーション",
        customerTab1: "サービサーセグメンテーション",
        customerTab2: "詳細",
        customerTab3: "RFMダイアグラム",
        table: {
          no: "No",
          className: "クラス名",
          quantity: "量",
          servicerName: "サービサー名",
          recency: "リーセンシー",
          frequency: "フリークエンシー",
          monetary: "モニタリー"
        }
      },
      charts: {
        distributionOfSegmentation: "セグメント",
        descriptionOfTheSegments: "セグメントの説明",
        distributionOfRecency: "最新購入日の分布",
        distributionOfFrequency: "購入回数の分布",
        distributionOfMForeachFAndR: "各F及びRのMの分布",
        distributionOfTheServicersAmongTheSegments: "セグメント間のサービサー分布",
        distributionOfTheCustomersAmongTheSegments: "セグメント間のカスタマー分布"
      }
    },
    campaignManagement: {
      coupon: {
        table: {
          no: "No",
          code: "コード",
          title: "タイトル",
          amount: "割引額",
          start: "開始日付",
          end: "終了日付",
          status: "ステータス",
          type: "タイプ",
          action: "アクション",
          transactionId: "トランザクションID",
          customerId: "顧客ID",
          servicerId: "サービサーID",
          couponAmount: "クーポン金額",
          usedAt: "使用日",
          expiredAt: "有効期限",
          completedAt: "完了日",
          issuedAt: "発行日時"
        },
        couponList: "クーポン一覧",
        usageCouponList: "使用一覧",
        usageCouponListOf: "{{coupon}}の使用一覧",
        issueCouponList: "発行一覧",
        issueCouponListOf: "{{coupon}}の発行一覧",
        createNewCoupon: "クーポン作成",
        updateCoupon: "クーポンの更新",
        confirmActivate: "このクーポンを有効にしますか?",
        confirmDeActivate: "このクーポンを無効にしますか?",
        confirmDelete: "このクーポンを削除しますか?",
        coupon_information: "クーポン情報",
        announcement_information: "キャンペーン情報",
        coupon_banner_image: "クーポンバナーの画像",
        campaign_banner_image: "キャンペーンバナーの画像",
        campaign_link: "キャンペーンリンク",
        description: "説明",
        couponCode: "クーポンコード",
        conditions: "条件",
        couponChangeStatusSuccess: "クーポンのステータスを成功に更新しました。",
        couponDeleteSuccess: "クーポンを正常に削除しました。",
        secretKey: "秘密鍵",
        discountType: "値引き方法",
        fixedAmount: "固定金額(¥)",
        rate: "割合(%)",
        tag: "タグ",
        rules: {
          minimumAmount: "適用可能な最低金額",
          total_count: "クーポンの総数",
          limitPerUser: "ユーザーごとの利用可能数",
          allowServicerIds: "対象のサービサーID",
          allowCustomerIds: "対象のカスタマーID",
          restrictServicerIds: "対象外のサービサーID",
          restrictCustomerIds: "対象外のカスタマーID",
          issueCouponNumber: "発行枚数",
          availableDays: "発行後の有効日数",
          maxServiceUsage: "サービスの最大利用回数",
          minServiceUsage: "サービスの最小利用回数",
          endIssued: "クーポン発行の締切日",
          startDate: "クーポン利用開始日",
          endDate: "クーポン利用終了日",
          startIssue: "発行開始日",
          totalIssuableCount: "発行可能総数",
          maxServiceUsageForIssue: "発行時のサービス利用数上限",
          minServiceUsageForIssue: "発行時のサービス利用数下限",
          allowMenuIds: "対象のメニューID",
          availableBookingFrom: "予約可能の開始時間",
          availableBookingTo: "予約可能の終了時間",
          availableBookingTo: "予約可能の終了時間",
          availableBookingStartTimeFrom: "有効な予約時間帯 (From)",
          availableBookingStartTimeTo: "有効な予約時間帯 (To)",
          ableToIssueMultiple: "複数発行できる",
          maxDiscountAmount: "最大割引額"
        },
        type: {
          public: "パブリック",
          private: "プライベート"
        }
      },
      point: {
        secretKey: "秘密鍵",
        year: "年",
        month: "ヶ月",
        expiryDuration: "有効期限",
        pointExchangeRate: "ポイント為替レート",
        currency: "通貨(3文字)",
        pointToCurrency: "ポイント->通貨",
        currencyToPoint: "通貨->ポイント",
        confirmDelete: "削除してもよろしいでしょうか。",
        deletedPointExchangeRateSuccess: "削除しました。",
        pointTransferRate: "ポイント交換レート",
        product: "プロダクト",
        transferRate: "交換レート",
        minimumPoint: "最小ポイント数",
        multiple: "倍数",
        searchPointHistory: "ポイント履歴の検索",
        userId: "ユーザーID",
        referenceId: "リファレンスID",
        createdFrom: "作成期間の開始日",
        createdTo: "作成期間の終了日",
        addUsePoint: "ポイントの追加/使用",
        expiryType: "有効期限のタイプ",
        status: "ステータス",
        transferable: "交換可能",
        notTransferable: "交換不可",
        point: "ポイント",
        expiryDate: "有効期限",
        createdDate: "作成日",
        approved: "承認済み",
        cancelled: "キャンセル",
        add: "追加",
        use: "使用",
        nonExp: "非有効期限",
        rolling: "ローリング有効期限",
        fixed: "固定有効期限",
        searchTotalPoint: "ユーザーの合計ポイントの検索",
        updatedFrom: "更新開始日",
        updatedTo: "更新終了日",
        rollingExpiryFrom: "ローリング開始日",
        rollingExpiryTo: "ローリング終了日",
        fixedExpiryFrom: "固定有効期限の開始日",
        fixedExpiryTo: "固定有効期限の終了日",
        totalPoint: "合計ポイント",
        nonExpPoint: "非有効期限のポイント",
        rollExpPoint: "ローリング有効期限のポイント",
        rollExpDate: "ローリング有効期限切れ日付",
        fixedExpPoint: "固定有効期限のポイント",
        fixedExpDate: "固定有効期限切れ日付",
        createdDate: "作成日",
        totalPointDaily: "デイリー合計ポイント",
        multiple: "倍数",
        file: "ファイル",
        lastModified: "最終更新日",
        download: "ダウンロード",
        transferPointHistoryDaily: "毎日のポイント交換履歴",
        transferPointHistoryMonthly: "毎月のポイント交換履歴"
      },
      invitation: {
        invitationCouponSetting: "友達紹介クーポンの設定",
        invitationCoupon: "招待クーポン",
        incentiveCoupon: "インセンティブクーポン",
        invitationLink: "発行招待クーポン一覧",
        incentiveLink: "発行インセンティブクーポン一覧",
        codes: "紹介コード",
        invitationCoupons: "この招待状を削除しますか。",
        confirmDelete: "この招待状を削除しますか。",
        invitation: "招待",
        incentive: "インセンティブ",
        table: {
          no: "No",
          code: "コード",
          customerId: "顧客ID",
          invitationCoupons: "招待クーポン",
          incentiveCoupons: "インセンティブクーポン",
          createdDate: "作成日",
          action: "アクション"
        },
        type: {
          invitation: "紹介",
          incentive: "インセンティブ"
        },
        activatePopupTitle: "紹介クーポンキャンペーンを有効にしますか？",
        activatePopupBody: "キャンペーンを有効にすると、ユーザーが招待コードを取得できるようになり、新規ユーザーに新しく紹介クーポンを発行できるようになります。",
        deactivatePopupTitle: "紹介クーポンキャンペンを無効にしてもよろしいでしょうか？",
        deactivatePopupBody: "キャンペーンを無効にすると、ユーザーが招待コードを取得できなくなり、新規ユーザーに新しく紹介クーポンを発行できなくなります。",
        deletePopupTitle: "紹介クーポンキャンペンを削除してもよろしいでしょうか？",
        deletePopupBody: "キャンペーンを削除すると、発行済のクーポンは全て無効となり、利用できなくなります。また、ユーザーが招待コードを取得することもできなくなります。一度実行した操作は、元に戻すことはできません。ご注意ください。",
      },
      couponSetting: {
        imageSize: "画像サイズ",
        couponBannerSmallSize: "クーポンバナー (小さいサイズ)",
        couponBannerLargeSize: "クーポンバナー (大きいサイズ)",
        announcementBannerSmallSize: "キャンペーンバナー (小さいサイズ)",
        announcementBannerLargeSize: "キャンペーンバナー (大きいサイズ)",
        height: "高さ",
        width: "幅",
        secretKey: "秘密鍵",
        couponType: "クーポンタイプ",
        privateCoupon: "プライベートクーポン",
        publicCoupon: "パブリッククーポン",
        invitationIncentiveCoupon: "招待クーポン",
        available: "利用する",
        unavailable: "利用しない",
        updateCouponTypeSettingSuccess: "クーポンタイプ設定を成功に編集しました。",
        tagList: "タグ一覧",
        createNewTag: "新規タグ作成",
        confirmDelete: "このタグを削除してもよろしいですか",
        name: "名前",
        table: {
          no: "No",
          name: "名前",
          action: "アクション"
        },
        createTag: "タグ作成",
        updateTag: "タグ更新"
      }
    }
  },
  message: {
    dayFromIsNotBeforeDayTo: "不正な期間を選択しました。",
    error_past_day: "過去日付を選択できません。",
    error: "エラー",
    noNetwork: "ネットワークエラー",
    isRequired: "このフィールドは必須項目です。",
    isInvalid: "このフィールドは正しく入力してください",
    saveSuccess: "保存成功しました!",
    deleteSuccess: "削除成功しました!",
    errorUndefined: "未定義のエラー",
    badRequest: "要求の形式が正しくありません",
    isNumber: "数字を入力してください。",
    isMinValue: "0より大きい整数を入力してください。",
    registerSuccess: "登録に 成功 しました。",
    productCodeFormat: "この項目は小文字 (a-z)、および数字 (0-9)のフォーマットを設定してください。",
    productNameFormat:
      "この項目は大文字 (A-Z)、小文字 (a-z)、および数字 (0-9)のフォーマットを設定してください。",
    notIncluceSpaceFormat: "名前にはスペースを含めないでください。",
    minRateAmountInput: "割合(%)の最小値は1です。",
    maxRateAmountInput: "割合(%)の最大値は100です。",
    required: {
      image_coupon: "キャンペーン用画像を登録してください",
      email: "メールアドレスは必須項目です",
      fullname: "氏名は必須項目です",
      nickname: "ニックネームは必須項目です",
      phone: "電話番号は半角数字で入力してください",
      name: "名前は必須項目です",
      role: "ロールは必須項目です",
      product: "プロダクトは必須項目です",
      amount: "金額が必要です"
    },
    invalid: {
      email: "メールアドレスを正しく入力してください",
      fullname: "氏名はローマ字又はカタカナで入力してください",
      nickname: "ニックネームはローマ字又はカタカナで入力してください",
      amount: "割引額が不正です。"
    },
    errorSaveTargetNoData: "ターゲットリストにメールがありませんのでSendgridへ登録できません。",
    maxsingleSendName: "シングルセンド名は半角80文字以内で設定してください。",
    errorNoValueMonthYear: "00年00ヶ月を設定しないでください。",
    noInvitationCouponSetting: "友達紹介クーポンの設定はされていません。",
    duplicateCurrencyValue: "通貨設定が既にあります。",
    inviteSuperAdminFail: "入力メールアドレスは既にスーパーアドミンです",
    minLengthSecretKeyValue: "秘密鍵は24文字以上が必要となります。",
    createTagSuccess: "タグを作成できました。",
    updateTagSuccess: "タグの情報を更新できました。",
    maxLengthTagName: "タグ名は全角50文字まで入力してください",
    associateTagWithCouponSuccess: "クーポンにタグを紐つくことができました",
    maxLengthAppId: "アプリIDの最大文字数は15文字です。",
    maxLengthAppName: "アプリ名の最大文字数は 20 文字です。",
    maxLengthPassphrase: "パスフレーズの最大文字数は 20 文字です。",
    minLength: "最低文字数は{{minLength}}文字です。",
    maxLength: "最大文字数は{{maxLength}}文字です。",
    matchRegex: "有効な値を入力してください。{{descriptionRegex}}のみ受け付けられます。",
    coupon: {
      codeRegexDescription: "大文字アルファベットと数字 (A-Z 0-9)",
    }
  },
  apiResponseMessage: {
    failValidate: "検証に合格しませんでした",
    email_registed: "メールアドレスはすでに存在しています",
    registed: "このフィールドは既に存在しています",
    invalidRequest: "エラーでアクセスできませんでした。",
    super_admin_exists: "入力メールアドレスは既にスーパーアドミンです。",
    theCurrentProductHasNotRegisteredCouponService:
      "このプロダクトはクーポンサービスを登録しません。",
    noSecretKeyFound: "秘密鍵がみつかりません。"
  },
  sendgrid: {
    event: {
      processed: "メール送信リクエストの受付",
      delivered: "宛先サーバによるメール受付",
      bounce: "恒久的な配信エラー",
      deferred: "一時的な配信エラー",
      blocked: "宛先サーバが受け付けなかった",
      dropped: "送信リクエストの破棄",
      spamreport: "迷惑メール報告",
      unsubscribe: "配信停止",
      group_unsubscribe: "グループ配信停止",
      group_resubscribe: "配信再開"
    }
  }
};
