/* eslint-disable react/prop-types */
import React from "react";
import i18n from "i18n";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Select as AntSelect, Button, Divider, Row, Alert, Empty, Modal } from "antd";

import "./style.less";
import constants from "utils/constants";
import { Select, TextInput, DatePicker } from "components/common";
import moment from "moment";

export const RenderParamValueFieldByType = (input) => {
  const { type, key } = input;
  let output;
  switch (type) {
    case constants.DATA_TYPE.STRING:
      output = <TextInput name={key} placeholder={i18n.t("placeholder.pleaseEnter")} />;
      break;
    case constants.DATA_TYPE.BOOLEAN:
      output = (
        <Select
          name={key}
          options={[
            {
              key: 1,
              name: "TRUE"
            },
            {
              key: 0,
              name: "FALSE"
            }
          ]}
        />
      );
      break;
    case constants.DATA_TYPE.NUMBER:
      output = (
        <TextInput inputType="number" name={key} placeholder={i18n.t("placeholder.pleaseEnter")} />
      );
      break;
    case constants.DATA_TYPE.DATETIME:
      output = (
        <DatePicker
          name={key}
          placeholder={i18n.t("placeholder.pleaseSelect")}
          format={constants.FORMAT.DATETIME_FORMAT}
        />
      );
      break;
    case constants.DATA_TYPE.DATETIME_START:
      output = (
        <DatePicker
          name={key}
          placeholder={i18n.t("placeholder.pleaseSelect")}
          showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
          format={constants.FORMAT.DATETIME_FORMAT}
        />
      );
      break;
    case constants.DATA_TYPE.DATETIME_END:
      output = (
        <DatePicker
          name={key}
          placeholder={i18n.t("placeholder.pleaseSelect")}
          showTime={{ defaultValue: moment("23:59:59", "HH:mm:ss") }}
          format={constants.FORMAT.DATETIME_FORMAT}
        />
      );
      break;
    case constants.DATA_TYPE.DAY:
      output = (
        <TextInput
          inputType="number"
          name={key}
          min={1}
          placeholder={i18n.t("placeholder.pleaseEnter")}
        />
      );
      break;
    case constants.DATA_TYPE.MONTH:
      output = (
        <Select
          name={key}
          options={[
            {
              key: 1,
              name: "01"
            },
            {
              key: 2,
              name: "02"
            },
            {
              key: 3,
              name: "03"
            },
            {
              key: 4,
              name: "04"
            },
            {
              key: 5,
              name: "05"
            },
            {
              key: 6,
              name: "06"
            },
            {
              key: 7,
              name: "07"
            },
            {
              key: 8,
              name: "08"
            },
            {
              key: 9,
              name: "09"
            },
            {
              key: 10,
              name: "10"
            },
            {
              key: 11,
              name: "11"
            },
            {
              key: 12,
              name: "12"
            }
          ]}
        />
      );
      break;
    default:
      output = <TextInput name={key} placeholder={i18n.t("placeholder.pleaseEnter")} />;
      break;
  }

  return output;
};

export const GetColumnSearchProps = (input) => {
  const {
    dataIndex,
    setFilter,
    callback,
    filterOptionsByKey = {},
    getFilterOptionsByKey,
    filterDropdownVisible,
    filterValueApplied,
    tempFilterValues,
    setTempFilterValues
  } = input;
  let selectSearch;

  return {
    // filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : 'undefined' }} />,
    filteredValue: filterValueApplied[dataIndex] ? [1] : [],
    // onFilter: (value, record) =>
    //   (record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (!filterOptionsByKey[dataIndex] && getFilterOptionsByKey) {
          getFilterOptionsByKey(dataIndex);
        }
        setTimeout(() => {
          selectSearch.focus();
          setTempFilterValues(filterValueApplied[dataIndex]);
        }, 100);
      } else {
        setTempFilterValues();
      }
    },
    filterDropdown: ({ selectedKeys, confirm }) => (
      <div className="my-filter-column">
        <AntSelect
          ref={(node) => {
            selectSearch = node;
          }}
          mode="multiple"
          size="large"
          placeholder={i18n.t("placeholder.searchToSelect")}
          allowClear
          listHeight={120}
          value={tempFilterValues}
          onChange={(value) => {
            if (value.length) {
              setTempFilterValues(value);
            } else {
              setTempFilterValues();
            }

            if (setFilter) {
              if (value.length) {
                setFilter({
                  key: dataIndex,
                  value: value
                });
              } else {
                setFilter();
              }
            }
          }}
          optionFilterProp="children"
          showAction="focus"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // filterSort={(optionA, optionB) =>
          //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          // }
          style={{ width: 250, fontSize: 14 }}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={i18n.t("placeholder.noData")}
            />
          }
          dropdownRender={(menu) => (
            <div style={{ display: filterDropdownVisible ? "" : "none" }}>
              {menu}
              <Divider style={{ marginBottom: 10 }} />
              <Row justify="end" style={{ margin: "0 10px 5px 0" }}>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  size="small"
                  onClick={() => {
                    confirm();
                    callback({ isFilter: true });
                  }}
                >
                  {i18n.t("action.search")}
                </Button>
              </Row>
            </div>
          )}
        >
          {/* {children} */}
          <>
            {filterOptionsByKey[dataIndex] &&
              filterOptionsByKey[dataIndex].map((option) => {
                let value = "";
                if (option[dataIndex] === "TRUE") {
                  value = 1;
                } else if (option[dataIndex] === "FALSE") {
                  value = 0;
                } else {
                  value = option[dataIndex] || option.value;
                }
                return (
                  <AntSelect.Option value={value} key={option[dataIndex] || option.key || ""}>
                    {option[dataIndex] || option.name}
                  </AntSelect.Option>
                );
              })}
          </>
        </AntSelect>
      </div>
    )
  };
};

export const RenderSystemAlert = (input) => {
  const { message, callback, type = "warning" } = input;

  const output = (
    <Alert
      message={message}
      description={
        <a href="# " onClick={callback}>
          {i18n.t("content.user.anotherLoginAcc")}
        </a>
      }
      type={type}
      showIcon
      closable
    />
  );

  return output;
};

export const Confirm = (input) => {
  const { onOk, title } = input;
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content: "",
    okText: i18n.t("action.cancel"),
    cancelText: i18n.t("action.ok"),
    okButtonProps: {
      type: "default"
    },
    cancelButtonProps: {
      type: "primary"
    },
    onCancel: onOk
  });
};

export const Warning = (input) => {
  const { onOk, title, content = "" } = input;
  Modal.warning({
    title,
    content,
    onOk
  });
};
