export const ERROR = "@TargetListSpoolProvider/ERROR";

export const RESET_SEND_SCHEDULE = "@TargetListSpoolProvider/RESET_SEND_SCHEDULE";
export const RESET_TARGET_TEMPLATE_RESULT = "@TargetListSpoolProvider/RESET_TARGET_TEMPLATE_RESULT";
export const RESET_TARGER_LIST_TEMPLATE = "@TargetListSpoolProvider/RESET_TARGER_LIST_TEMPLATE";
export const GET_TARGER_LIST_TEMPLATE_REQUEST =
  "@TargetListSpoolProvider/GET_TARGER_LIST_TEMPLATE_REQUEST";
export const GET_TARGER_LIST_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/GET_TARGER_LIST_TEMPLATE_SUCCESS";
export const GET_TARGER_LIST_TEMPLATE_FAILED =
  "@TargetListSpoolProvider/GET_TARGER_LIST_TEMPLATE_FAILED";

export const RUN_TARGET_TEMPLATE_REQUEST = "@TargetListSpoolProvider/RUN_TARGET_TEMPLATE_REQUEST";
export const RUN_TARGET_TEMPLATE_SUCCESS = "@TargetListSpoolProvider/RUN_TARGET_TEMPLATE_SUCCESS";
export const RUN_TARGET_TEMPLATE_FAILED = "@TargetListSpoolProvider/RUN_TARGET_TEMPLATE_FAILED";

export const GET_FILTER_VALUE_REQUEST = "@TargetListSpoolProvider/GET_FILTER_VALUE_REQUEST";
export const GET_FILTER_VALUE_SUCCESS = "@TargetListSpoolProvider/GET_FILTER_VALUE_SUCCESS";

export const EXPORT_REQUEST = "@TargetListSpoolProvider/EXPORT_REQUEST";
export const EXPORT_SUCCESS = "@TargetListSpoolProvider/EXPORT_SUCCESS";
export const EXPORT_FAILED = "@TargetListSpoolProvider/EXPORT_FAILED";

export const SAVE_TARGET_REQUEST = "@TargetListSpoolProvider/SAVE_TARGET_REQUEST";
export const SAVE_TARGET_SUCCESS = "@TargetListSpoolProvider/SAVE_TARGET_SUCCESS";
export const SAVE_TARGET_FAILED = "@TargetListSpoolProvider/SAVE_TARGET_FAILED";

export const GET_TARGET_LIST_SPOOL_LOG_REQUEST =
  "@TargetListSpoolProvider/GET_TARGET_LIST_SPOOL_LOG_REQUEST";
export const GET_TARGET_LIST_SPOOL_LOG_SUCCESS =
  "@TargetListSpoolProvider/GET_TARGET_LIST_SPOOL_LOG_SUCCESS";

export const GET_TARGET_LIST_SPOOLED_REQUEST =
  "@TargetListSpoolProvider/GET_TARGET_LIST_SPOOLED_REQUEST";
export const GET_TARGET_LIST_SPOOLED_SUCCESS =
  "@TargetListSpoolProvider/GET_TARGET_LIST_SPOOLED_SUCCESS";
export const GET_TARGET_LIST_SPOOLED_FAILED =
  "@TargetListSpoolProvider/GET_TARGET_LIST_SPOOLED_FAILED";

export const EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST =
  "@TargetListSpoolProvider/EXPORT_TARGET_SPOOL_LOG_LIST_REQUEST";
export const EXPORT_TARGET_SPOOL_LOG_LIST_SUCCESS =
  "@TargetListSpoolProvider/EXPORT_TARGET_SPOOL_LOG_LIST_SUCCESS";
export const EXPORT_TARGET_SPOOL_LOG_LIST_FAILED =
  "@TargetListSpoolProvider/EXPORT_TARGET_SPOOL_LOG_LIST_FAILED";

export const EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST =
  "@TargetListSpoolProvider/EXPORT_TARGET_SPOOL_LOG_DETAIL_REQUEST";
export const EXPORT_TARGET_SPOOL_LOG_DETAIL_SUCCESS =
  "@TargetListSpoolProvider/EXPORT_TARGET_SPOOL_LOG_DETAIL_SUCCESS";
export const EXPORT_TARGET_SPOOL_LOG_DETAIL_FAILED =
  "@TargetListSpoolProvider/EXPORT_TARGET_SPOOL_LOG_DETAIL_FAILED";

export const CREATE_NEW_SENDER_REQUEST = "@TargetListSpoolProvider/CREATE_NEW_SENDER_REQUEST";
export const CREATE_NEW_SENDER_SUCCESS = "@TargetListSpoolProvider/CREATE_NEW_SENDER_SUCCESS";
export const CREATE_NEW_SENDER_FAILED = "@TargetListSpoolProvider/CREATE_NEW_SENDER_FAILED";

export const GET_SENDER_LIST_REQUEST = "@TargetListSpoolProvider/GET_SENDER_LIST_REQUEST";
export const GET_SENDER_LIST_SUCCESS = "@TargetListSpoolProvider/GET_SENDER_LIST_SUCCESS";
export const GET_SENDER_LIST_FAILED = "@TargetListSpoolProvider/GET_SENDER_LIST_FAILED";

export const DELETE_SENDER_REQUEST = "@TargetListSpoolProvider/DELETE_SENDER_REQUEST";
export const DELETE_SENDER_SUCCESS = "@TargetListSpoolProvider/DELETE_SENDER_SUCCESS";
export const DELETE_SENDER_FAILED = "@TargetListSpoolProvider/DELETE_SENDER_FAILED";

export const CREATE_SINGLE_SEND_REQUEST = "@TargetListSpoolProvider/CREATE_SINGLE_SEND_REQUEST";
export const CREATE_SINGLE_SEND_SUCCESS = "@TargetListSpoolProvider/CREATE_SINGLE_SEND_SUCCESS";
export const CREATE_SINGLE_SEND_FAILED = "@TargetListSpoolProvider/CREATE_SINGLE_SEND_FAILED";

export const GET_SENDER_SELECT_LIST_REQUEST =
  "@TargetListSpoolProvider/GET_SENDER_SELECT_LIST_REQUEST";
export const GET_SENDER_SELECT_LIST_SUCCESS =
  "@TargetListSpoolProvider/GET_SENDER_SELECT_LIST_SUCCESS";
export const GET_SENDER_SELECT_LIST_FAILED =
  "@TargetListSpoolProvider/GET_SENDER_SELECT_LIST_FAILED";

export const GET_SINGLE_SEND_LIST_REQUEST = "@TargetListSpoolProvider/GET_SINGLE_SEND_LIST_REQUEST";
export const GET_SINGLE_SEND_LIST_SUCCESS = "@TargetListSpoolProvider/GET_SINGLE_SEND_LIST_SUCCESS";
export const GET_SINGLE_SEND_LIST_FAILED = "@TargetListSpoolProvider/GET_SINGLE_SEND_LIST_FAILED";

export const DELETE_SINGLE_SEND_REQUEST = "@TargetListSpoolProvider/DELETE_SINGLE_SEND_REQUEST";
export const DELETE_SINGLE_SEND_SUCCESS = "@TargetListSpoolProvider/DELETE_SINGLE_SEND_SUCCESS";
export const DELETE_SINGLE_SEND_FAILED = "@TargetListSpoolProvider/DELETE_SINGLE_SEND_FAILED";

export const GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST =
  "@TargetListSpoolProvider/GET_TARGET_LIST_EMAIL_TEMPLATE_REQUEST";
export const GET_TARGET_LIST_EMAIL_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/GET_TARGET_LIST_EMAIL_TEMPLATE_SUCCESS";

export const CREATE_EMAIL_TEMPLATE_REQUEST =
  "@TargetListSpoolProvider/CREATE_EMAIL_TEMPLATE_REQUEST";
export const CREATE_EMAIL_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/CREATE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_EMAIL_TEMPLATE_FAILED = "@TargetListSpoolProvider/CREATE_EMAIL_TEMPLATE_FAILED";

export const UPDATE_EMAIL_TEMPLATE_REQUEST =
  "@TargetListSpoolProvider/UPDATE_EMAIL_TEMPLATE_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/UPDATE_EMAIL_TEMPLATE_SUCCESS";

export const DELETE_EMAIL_TEMPLATE_REQUEST =
  "@TargetListSpoolProvider/DELETE_EMAIL_TEMPLATE_REQUEST";
export const CANCEL_LOADING_TABLE_EMAIL_TEMPLATE =
  "@TargetListSpoolProvider/CANCEL_LOADING_TABLE_EMAIL_TEMPLATE";
export const GET_DETAIL_EMAIL_TEMPLATE_REQUEST =
  "@TargetListSpoolProvider/GET_DETAIL_EMAIL_TEMPLATE_REQUEST";
export const GET_DETAIL_EMAIL_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/GET_DETAIL_EMAIL_TEMPLATE_SUCCESS";
export const GET_COPY_EMAIL_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/GET_COPY_EMAIL_TEMPLATE_SUCCESS";
export const GET_EDIT_EMAIL_TEMPLATE_SUCCESS =
  "@TargetListSpoolProvider/GET_EDIT_EMAIL_TEMPLATE_SUCCESS";
export const RESET_EMAIL_DETAIL_TEMPLATE = "@TargetListSpoolProvider/RESET_EMAIL_DETAIL_TEMPLATE";

export const GET_SEND_SCHEDULE_LIST_REQUEST =
  "@TargetListSpoolProvider/GET_SEND_SCHEDULE_LIST_REQUEST";
export const GET_SEND_SCHEDULE_LIST_SUCCESS =
  "@TargetListSpoolProvider/GET_SEND_SCHEDULE_LIST_SUCCESS";
export const CREATE_SEND_SCHEDULE_REQUEST = "@TargetListSpoolProvider/CREATE_SEND_SCHEDULE_REQUEST";
export const CREATE_SEND_SCHEDULE_SUCCESS = "@TargetListSpoolProvider/CREATE_SEND_SCHEDULE_SUCCESS";
export const GET_DETAIL_SEND_SCHEDULE_REQUEST =
  "@TargetListSpoolProvider/GET_DETAIL_SEND_SCHEDULE_REQUEST";
export const GET_COPY_SEND_SCHEDULE_SUCCESS =
  "@TargetListSpoolProvider/GET_COPY_SEND_SCHEDULE_SUCCESS";
export const GET_DETAIL_SEND_SCHEDULE_SUCCESS =
  "@TargetListSpoolProvider/GET_DETAIL_SEND_SCHEDULE_SUCCESS";
export const UPDATE_STATUS_SEND_SCHEDULE_REQUEST =
  "@TargetListSpoolProvider/UPDATE_STATUS_SEND_SCHEDULE_REQUEST";
export const CREATE_SEND_SCHEDULE_FAILED = "@TargetListSpoolProvider/CREATE_SEND_SCHEDULE_FAILED";
export const GET_DETAIL_EMAIL_TEMPLATE_SCHEDULE_SUCCESS =
  "@TargetListSpoolProvider/GET_DETAIL_EMAIL_TEMPLATE_SCHEDULE_SUCCESS";
export const RESET_EMAIL_DETAIL_TEMPLATE_SCHEDULE =
  "@TargetListSpoolProvider/RESET_EMAIL_DETAIL_TEMPLATE_SCHEDULE";
