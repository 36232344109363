import { all } from "redux-saga/effects";
import watchApp from "providers/AppProvider/saga";
import watchAuth from "providers/AuthProvider/saga";
import watchTargetListSpoolProvider from "providers/TargetListSpoolProvider/saga";
import watchUserProvider from "providers/UserProvider/saga";
import watchAISupportProvider from "providers/AISupportProvider/saga";
import watchAuthCampaign from "providers/CampaignProvider/saga";
import watchBigQuery from "providers/BigQueryProvider/saga";
import watchPointApi from "providers/PointProvider/saga";

export default function* rootSaga() {
  yield all([
    watchApp(),
    watchAuth(),
    watchTargetListSpoolProvider(),
    watchUserProvider(),
    watchAISupportProvider(),
    watchAuthCampaign(),
    watchBigQuery(),
    watchPointApi()
  ]);
}
