import { put, takeLeading, call } from 'redux-saga/effects';
import _ from 'lodash';

import helpers from 'utils/helpers';
import request from 'utils/api';
import { safe } from 'providers/GeneralProvider/saga';
import { notification } from 'antd';
import i18n from 'i18n';
import {
  GET_POINT_API_CONFIG_SECRET_KEY_REQUEST,
  GET_POINT_API_EXPIRY_DURATION_REQUEST,
  UPDATE_POINT_API_EXPIRY_DURATION_REQUEST,
  GET_POINT_API_EXCHANGE_RATE_REQUEST,
  UPDATE_POINT_API_EXCHANGE_RATE_REQUEST,
  GET_POINT_API_TRANSFER_RATE_REQUEST,
  UPDATE_POINT_API_TRANSFER_RATE_REQUEST,
  GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST,
  GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST,
  GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_REPORT_REQUEST,
  GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_REQUEST
} from './constants';
import {
  getPointApiConfigSecretFailed,
  getPointApiConfigSecretSuccess,
  getPointApiExpiryDurationFailed,
  getPointApiExpiryDurationSuccess,
  updatePointApiExpiryDurationFailed,
  updatePointApiExpiryDurationSuccess,
  getPointApiExchangeRateFailed,
  getPointApiExchangeRateSuccess,
  updatePointApiExchangeRateFailed,
  updatePointApiExchangeRateSuccess,
  getPointApiTransferRateFailed,
  getPointApiTransferRateSuccess,
  updatePointApiTransferRateFailed,
  updatePointApiTransferRateSuccess,
  getPointApiTotalPointSearchFailed,
  getPointApiTotalPointSearchSuccess,
  getPointApiPointHistorySearchFailed,
  getPointApiPointHistorySearchSuccess,
  getPointApiCsvTotalPointDailyReportFailed,
  getPointApiCsvTotalPointDailyReportSuccess,
  getPointApiCsvTotalPointDailyDownloadFailed,
  getPointApiCsvTotalPointDailyDownloadSuccess,
  getPointApiTransferHistoryDailyReportFailed,
  getPointApiTransferHistoryDailyReportSuccess,
  getPointApiTransferHistoryDailyDownloadFailed,
  getPointApiTransferHistoryDailyDownloadSuccess,
  getPointApiTransferHistoryMonthlyReportFailed,
  getPointApiTransferHistoryMonthlyReportSuccess,
  getPointApiTransferHistoryMonthlyDownloadFailed,
  getPointApiTransferHistoryMonthlyDownloadSuccess
} from './actions';

export function* handlePointApiConfigSecretRequest() {
  const pointPath = helpers.getPointPath('config/:product/secret_key')
  const requestBody = helpers.requestBodyPoint('get', pointPath);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiConfigSecretSuccess(_.get(result, 'payload')));
}

export function* handlePointApiConfigSecretError(error) {
  yield put(getPointApiConfigSecretFailed(error));
}

export function* handlePointApiExpiryDurationRequest() {
  const pointPath = helpers.getPointPath('config/:product/expiry_duration');
  const requestBody = helpers.requestBodyPoint('get', pointPath);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiExpiryDurationSuccess(_.get(result, 'payload')));
}

export function* handlePointApiExpiryDurationError(error) {
  yield put(getPointApiExpiryDurationFailed(error));
}

export function* handleUpdatePointApiExpiryDurationRequest(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  const pointPath = helpers.getPointPath('config/:product/expiry_duration');
  const requestBody = helpers.requestBodyPoint('post', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  if (result.code === '200') {
    notification.success({
      message: i18n.t('message.saveSuccess'),
      duration: 2
    });
    yield put(updatePointApiExpiryDurationSuccess(_.get(result, 'payload')));
  }
}

export function* handleUpdatePointApiExpiryDurationError(error) {
  yield put(updatePointApiExpiryDurationFailed(error));
}

export function* handlePointApiExchangeRateRequest() {
  const pointPath = helpers.getPointPath('config/:product/exchange_rate');
  const requestBody = helpers.requestBodyPoint('get', pointPath);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiExchangeRateSuccess(_.get(result, 'payload')));
}

export function* handlePoinApiExchangeRateError(error) {
  yield put(getPointApiExchangeRateFailed(error));
}

export function* handleUpdatePointApiExchangeRate(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  const pointPath = helpers.getPointPath('config/:product/exchange_rate');
  const requestBody = helpers.requestBodyPoint('post', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  if (result.code === '200') {
    notification.success({
      message: i18n.t('message.saveSuccess'),
      duration: 2
    });
    yield put(updatePointApiExchangeRateSuccess(_.get(result, 'payload')));
  }
}

export function* handleUpdatePointExchangeError(error) {
  yield put(updatePointApiExchangeRateFailed(error));
}

export function* handlePointApiTransferRateRequest() {
  const pointPath = helpers.getPointPath('config/:product/transfer_rate')
  const requestBody = helpers.requestBodyPoint('get', pointPath);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiTransferRateSuccess(_.get(result, 'payload')));
}

export function* handlePointApiTransferRateError(error) {
  yield put(getPointApiTransferRateFailed(error));
}

export function* handleUpdatePointApiTransferRate(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  const pointPath = helpers.getPointPath('config/:product/transfer_rate');
  const requestBody = helpers.requestBodyPoint('post', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  if (result.code === '200') {
    notification.success({
      message: i18n.t('message.saveSuccess'),
      duration: 2
    });
    yield put(updatePointApiTransferRateSuccess(_.get(result, 'payload')));
  }

}

export function* handleUpdatePointApiTransferRateError(error) {
  yield put(updatePointApiTransferRateFailed(error));
}

export function* handleGetPointApiTotalPointSearch(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  if (!_.get(data, 'sort')) {
    data.sort = 'created_at:desc';
  }

  const pointPath = helpers.getPointPath(':product/point/search');
  const requestBody = helpers.requestBodyPoint('get', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiTotalPointSearchSuccess(_.get(result, 'payload')));
}

export function* handleGetPointApiTotalPointSearchError(error) {
  yield put(getPointApiTotalPointSearchFailed(error));
}

export function* handleGetPointApiPointHistorySearch(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  if (!_.get(data, 'sort')) {
    data.sort = 'created_at:desc';
  }

  const pointPath = helpers.getPointPath(':product/point/histories');
  const requestBody = helpers.requestBodyPoint('get', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiPointHistorySearchSuccess(_.get(result, 'payload')));
}

export function* handleGetPointApiPointHistorySearchError(error) {
  yield put(getPointApiPointHistorySearchFailed(error));
}

export function* handlePointApiCsvTotalPointDailyReport(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  const pointPath = helpers.getPointPath(':product/csv/total_point_daily');
  const requestBody = helpers.requestBodyPoint('get', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiCsvTotalPointDailyReportSuccess(_.get(result, 'payload')));
}

export function* handlePointApiCsvDailyReportError(error) {
  yield put(getPointApiCsvTotalPointDailyReportFailed(error));
}

export function* handlePointApiCsvTotalPointDailyDownload(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const pointPath = helpers.getPointPath(':product/csv/total_point_daily/signed_url')
  const { data } = requestData;
  const requestBody = helpers.requestBodyPoint('get', pointPath, data, true);

  const result = yield call(request, 'points', 'post', requestBody);
  const dataUrlCsv = _.get(result, 'payload.csvBase64');
  const type = 'data:application/octet-stream;base64';
  const keyDownload = _.get(data, 'key');
  const fileName = keyDownload.replace(/\//g, '_');
  helpers.downloadFileCsv(dataUrlCsv, type, fileName);

  yield put(getPointApiCsvTotalPointDailyDownloadSuccess());
}

export function* handlePointApiCsvTotalPointDailyDownloadError(error) {
  yield put(getPointApiCsvTotalPointDailyDownloadFailed(error));
}

export function* handlePointApiCsvTransferHistoryDailyReport(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  const pointPath = helpers.getPointPath(':product/csv/transfer_history_daily');
  const requestBody = helpers.requestBodyPoint('get', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiTransferHistoryDailyReportSuccess(_.get(result, 'payload')));
}

export function* handlePointApCsvTransferHistoryDailyReportError(error) {
  yield put(getPointApiTransferHistoryDailyReportFailed(error));
}

export function* handlePointApiCsvTransferHistoryDailyDownload(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const pointPath = helpers.getPointPath(':product/csv/transfer_history_daily/signed_url')
  const { data } = requestData;
  const requestBody = helpers.requestBodyPoint('get', pointPath, data, true);

  const result = yield call(request, 'points', 'post', requestBody,);
  const dataUrlCsv = _.get(result, 'payload.csvBase64');
  const type = 'data:application/octet-stream;base64';
  const keyDownload = _.get(data, 'key');
  const fileName = keyDownload.replace(/\//g, '_');
  helpers.downloadFileCsv(dataUrlCsv, type, fileName);

  yield put(getPointApiTransferHistoryDailyDownloadSuccess());
}

export function* handlePointApiTransferHistoryDailyError(error) {
  yield put(getPointApiTransferHistoryDailyDownloadFailed(error));
}

export function* handleGetPointApiCsvTransferHistoryMonthlyReport(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload'));
  const { data } = requestData;
  const pointPath = helpers.getPointPath(':product/csv/transfer_history_monthly');
  const requestBody = helpers.requestBodyPoint('get', pointPath, data);

  const result = yield call(request, 'points', 'post', requestBody);
  yield put(getPointApiTransferHistoryMonthlyReportSuccess(_.get(result, 'payload')));
}

export function* handleGetPointApiCsvTransferHistoryMonthlyReportError(error) {
  yield put(getPointApiTransferHistoryMonthlyReportFailed(error));
}

export function* handleGetPointApiCsvTransferHistoryMonthlyDownload(input) {
  const requestData = helpers.deepCloneObject(_.get(input, 'payload', {}));
  const { data } = requestData;
  const pointPath = helpers.getPointPath(':product/csv/transfer_history_monthly/signed_url')
  const requestBody = helpers.requestBodyPoint('get', pointPath, data, true);

  const result = yield call(request, 'points', 'post', requestBody);
  const dataUrlCsv = _.get(result, 'payload.csvBase64');
  const type = 'data:application/octet-stream;base64';
  const keyDownload = _.get(data, 'key');
  const fileName = keyDownload.replace(/\//g, '_');
  helpers.downloadFileCsv(dataUrlCsv, type, fileName);
  
  yield put(getPointApiTransferHistoryMonthlyDownloadSuccess());
}

export function* handleGetPointApiCsvTransferHistoryMonthlyDownloadError(error) {
  yield put(getPointApiTransferHistoryMonthlyDownloadFailed(error));
}

export default function* watchPointApi() {
  yield takeLeading (
    GET_POINT_API_CONFIG_SECRET_KEY_REQUEST,
    safe(handlePointApiConfigSecretRequest, handlePointApiConfigSecretError)
  );
  yield takeLeading (
    GET_POINT_API_EXPIRY_DURATION_REQUEST,
    safe(handlePointApiExpiryDurationRequest, handlePointApiExpiryDurationError)
  );
  yield takeLeading (
    UPDATE_POINT_API_EXPIRY_DURATION_REQUEST,
    safe(handleUpdatePointApiExpiryDurationRequest, handleUpdatePointApiExpiryDurationError)
  );
  yield takeLeading (
    GET_POINT_API_EXCHANGE_RATE_REQUEST,
    safe(handlePointApiExchangeRateRequest, handlePoinApiExchangeRateError)
  );
  yield takeLeading (
    UPDATE_POINT_API_EXCHANGE_RATE_REQUEST,
    safe(handleUpdatePointApiExchangeRate, handleUpdatePointExchangeError)
  );
  yield takeLeading (
    GET_POINT_API_TRANSFER_RATE_REQUEST,
    safe(handlePointApiTransferRateRequest, handlePointApiTransferRateError)
  );
  yield takeLeading (
    UPDATE_POINT_API_TRANSFER_RATE_REQUEST,
    safe(handleUpdatePointApiTransferRate, handleUpdatePointApiTransferRateError)
  );
  yield takeLeading (
    GET_POINT_API_TOTAL_POINT_SEARCH_REQUEST,
    safe(handleGetPointApiTotalPointSearch, handleGetPointApiTotalPointSearchError)
  );
  yield takeLeading (
    GET_POINT_API_POINT_HISTORY_SEARCH_REQUEST,
    safe(handleGetPointApiPointHistorySearch, handleGetPointApiPointHistorySearchError)
  );
  yield takeLeading (
    GET_POINT_API_CSV_TOTAL_POINT_DAILY_REPORT_REQUEST,
    safe(handlePointApiCsvTotalPointDailyReport, handlePointApiCsvDailyReportError)
  );
  yield takeLeading (
    GET_POINT_API_CSV_TOTAL_POINT_DAILY_DOWNLOAD_REQUEST,
    safe(handlePointApiCsvTotalPointDailyDownload, handlePointApiCsvTotalPointDailyDownloadError)
  );
  yield takeLeading (
    GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_REPORT_REQUEST,
    safe(handlePointApiCsvTransferHistoryDailyReport, handlePointApCsvTransferHistoryDailyReportError)
  );
  yield takeLeading (
    GET_POINT_API_CSV_TRANSFER_HISTORY_DAILY_DOWNLOAD_REQUEST,
    safe(handlePointApiCsvTransferHistoryDailyDownload, handlePointApiTransferHistoryDailyError)
  );
  yield takeLeading (
    GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_REPORT_REQUEST,
    safe(handleGetPointApiCsvTransferHistoryMonthlyReport, handleGetPointApiCsvTransferHistoryMonthlyReportError)
  );
  yield takeLeading (
    GET_POINT_API_CSV_TRANSFER_HISTORY_MONTHLY_DOWNLOAD_REQUEST,
    safe(handleGetPointApiCsvTransferHistoryMonthlyDownload, handleGetPointApiCsvTransferHistoryMonthlyDownloadError)
  );
}
