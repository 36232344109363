import { put, takeLeading, call } from "redux-saga/effects";
import _ from "lodash";
import i18n from "i18n";
import { notification } from "antd";

import helpers from "utils/helpers";
import request from "utils/api";
import { safe } from "providers/GeneralProvider/saga";
import {
  USER_REGISTER_REQUEST,
  GET_USER_LIST_REQUEST,
  USER_APPROVAL_REQUEST,
  USER_DELETE_REQUEST,
  USER_UPDATE_REQUEST,
  USER_INVITE_REQUEST,
  ACCEPT_INVITE_REQUEST,
  GET_USER_PROFILE_REQUEST,
  USER_PROFILE_UPDATE_REQUEST,
  GET_USER_SETTING_REQUEST,
  USER_SETTING_UPDATE_REQUEST,
  REGISTER_COUPON_SERVICE_REQUEST,
  REGISTER_COUPON_SERVICE_V1_REQUEST,
  REGISTER_POINT_SERVICE_REQUEST,
  REGISTER_PRODUCT_REQUEST,
  SUPER_ADMIN_REGISTER_REQUEST
} from "./constants";
import {
  userRegisterSuccess,
  userRegisterFailed,
  getUserListSuccess,
  userApprovalSuccess,
  userDeleteSuccess,
  getUserListRequest,
  userUpdateSuccess,
  userInviteSuccess,
  userInviteFailed,
  acceptInviteFailed,
  acceptInviteSuccess,
  getUserProfileSuccess,
  userProfileUpdateSuccess,
  getUserSettingSuccess,
  userSettingUpdateSuccess,
  getUserSettingFail,
  userSettingUpdateFailed,
  registerCouponServiceSuccess,
  registerCouponServiceFailed,
  registerCouponServiceV1Success,
  registerCouponServiceV1Failed,
  registerPointServiceFailed,
  registerPointServiceSuccess,
  registerProductFailed,
  registerProductSuccess,
  superAdminRegisterSuccess,
  superAdminRegisterFailed
} from "./actions";
import { checkAuth0InfoRequest } from "providers/AuthProvider/actions";

export function* handleUserRegister(input) {
  const result = yield call(request, "auth/register", "post", _.get(input, "payload"));

  if (parseInt(_.get(result, "code", 0)) === 400) {
    yield put(userRegisterFailed(_.get(result, "payload")));
    return;
  }

  if (!_.isEmpty(result.payload)) {
    helpers.notify(i18n.t("content.user.userRegisterSuccess"));
    yield put(userRegisterSuccess(_.get(result, "payload")));
  }
}

export function* handleGetUserList(input) {
  const requestBody = helpers.deepCloneObject(_.get(input, "payload", {}));

  if (!_.get(requestBody, "sorts")) {
    requestBody.sorts = [];
  }

  requestBody.sorts.push({
    field: "updated_at",
    type: "desc"
  });

  const result = yield call(request, "user/list/", "post", requestBody);
  yield put(getUserListSuccess(_.get(result, "payload")));
}

export function* handleUserApproval(input) {
  const requestBody = {
    ..._.get(input, "payload.userApproveInfoPayload")
  };
  const result = yield call(request, "user/approve", "put", requestBody);
  if (_.get(result, "payload.userId")) {
    helpers.notify(i18n.t("content.user.userApprovalSuccess"));
    yield put(userApprovalSuccess(_.get(result, "payload")));
    yield put(getUserListRequest(_.get(input, "payload.userListPayload")));
  }
}

export function* handleUserDelete(input) {
  const requestBody = {
    ..._.get(input, "payload.userDeleteInfoPayload"),
    product: helpers.getProductId()
  };
  const result = yield call(request, "user/delete", "delete", null, { data: requestBody });
  if (_.get(result, "payload.deleted_id")) {
    helpers.notify(i18n.t("content.user.userDeleteSuccess"));
    yield put(userDeleteSuccess(_.get(result, "payload")));
    yield put(getUserListRequest(_.get(input, "payload.userListPayload")));
  }
}

export function* handleUserUpdate(input) {
  const requestBody = {
    role: _.get(input, "payload.userUpdateInfoPayload.role"),
    product: _.get(input, "payload.userUpdateInfoPayload.product")
  };
  const result = yield call(
    request,
    `user/edit/${_.get(input, "payload.userUpdateInfoPayload.id")}`,
    "put",
    requestBody
  );

  if (_.get(result, "payload.id")) {
    helpers.notify(i18n.t("content.user.userUpdateSuccess"));
    yield put(userUpdateSuccess(_.get(result, "payload")));
    yield put(getUserListRequest(_.get(input, "payload.userListPayload")));
  }
}

export function* handleUserInvite(input) {
  const loggedUserId = helpers.getUserId();
  const requestBody = _.pick(_.get(input, "payload.requestData", {}), ["email", "role", "product"]);
  requestBody.invited_id = loggedUserId;
  const result = yield call(request, "user/invite", "post", requestBody);

  if (parseInt(_.get(result, "code", 0)) === 400) {
    yield put(userInviteFailed({ errors: _.get(result, "payload", {}) }));
    return;
  }

  const handleReset = _.get(input, "payload.handleReset");
  if (handleReset) {
    handleReset();
  }
  helpers.notify(i18n.t("content.user.userInviteSuccess"));
  yield put(userInviteSuccess(_.get(result, "payload", {})));
}

export function* handleAcceptInvite(input) {
  const requestBody = {
    token: _.get(input, "payload.token", "")
  };

  const result = yield call(request, "user/check/token", "post", requestBody);

  if (parseInt(_.get(result, "code", 0)) === 400) {
    yield put(acceptInviteFailed(_.get(result, "payload", {})));
    return;
  }

  yield put(acceptInviteSuccess(_.get(result, "payload", {})));
}

export function* handleGetUserProfile(input) {
  const loggedUserId = helpers.getUserId();
  const result = yield call(request, `user/profile/${loggedUserId}`, "get");

  if (_.get(result, "payload.id")) {
    yield put(getUserProfileSuccess(_.get(result, "payload")));
  }
}

export function* handleUserProfileUpdate(input) {
  const loggedUserId = helpers.getUserId();
  const enableFieldUpdate = ["fullname", "nickname", "phone"];
  const requestBody = _.pick(_.get(input, "payload", {}), enableFieldUpdate);
  const result = yield call(request, `user/profile/update/${loggedUserId}`, "put", requestBody);

  if (_.get(result, "payload.id")) {
    helpers.notify(i18n.t("message.saveSuccess"));
    yield put(userProfileUpdateSuccess(_.get(result, "payload")));
    yield put(checkAuth0InfoRequest());
  }
}

export function* handleGetUserSetting() {
  const result = yield call(request, "setting", "get");
  if (_.get(result, "payload.id")) {
    yield put(getUserSettingSuccess(_.get(result, "payload")));
    return;
  }
  yield put(getUserSettingFail());
}

export function* handleUserSettingUpdate(input) {
  const requestBody = {
    setting_info: JSON.stringify(_.get(input, "payload"))
  };
  const result = yield call(request, "setting/update", "post", requestBody);
  if (_.get(result, "payload.id")) {
    helpers.notify(i18n.t("content.user.userSettingUpdateSuccess"));
    yield put(userSettingUpdateSuccess(_.get(result, "payload")));
  }
  yield put(userSettingUpdateFailed());
}

export function* handleRegisterCouponService(input) {
  const requestBody = _.get(input, "payload", {});
  const result = yield call(request, "register/service/coupon", "post", requestBody);

  if (result.code === "200") {
    notification.success({
      message: i18n.t("message.registerSuccess"),
      duration: 2
    });
    yield put(registerCouponServiceSuccess(_.get(result, "payload")));
  }
}

export function* handleRegisterCouponServiceError(error) {
  yield put(registerCouponServiceFailed(error));
}


export function* handleRegisterCouponServiceV1(input) {
  const requestBody = _.get(input, "payload", {});
  const result = yield call(request, "register/service/coupon-v1", "post", requestBody);

  if (result.code === "200") {
    notification.success({
      message: i18n.t("message.registerSuccess"),
      duration: 2
    });
    yield put(registerCouponServiceV1Success(_.get(result, "payload")));
  }
}

export function* handleRegisterCouponServiceV1Error(error) {
  yield put(registerCouponServiceV1Failed(error));
}

export function* handleRegisterPointService(input) {
  const requestBody = _.get(input, "payload", {});
  const result = yield call(request, "register/service/point", "post", requestBody);
  if (result.code === "200") {
    notification.success({
      message: i18n.t("message.registerSuccess"),
      duration: 2
    });
    yield put(registerPointServiceSuccess(_.get(result, "payload")));
  }
}

export function* handleRegisterPointServiceError(error) {
  yield put(registerPointServiceFailed(error));
}

export function* handleRegisterProduct(input) {
  const requestBody = _.get(input, "payload", {});
  const result = yield call(request, "product/register", "post", requestBody);

  if (result.code === "200") {
    notification.success({
      message: i18n.t("message.registerSuccess"),
      duration: 2
    });
    yield put(registerProductSuccess(_.get(result, "payload")));
  }
}

export function* handleRegisterProductError(error) {
  yield put(registerProductFailed(error));
}

export function* handleSuperAdminRegister(input) {
  const result = yield call(request, "user/invite_super_admin", "post", _.get(input, "payload"));

  if (parseInt(_.get(result, "code", 0)) === 400) {
    yield put(superAdminRegisterFailed({ errors: _.get(result, "payload", {}) }));
    return;
  }

  if (!_.isEmpty(result.payload)) {
    if (!_.isEmpty(result.payload.invitation_token)) {
      helpers.notify(i18n.t("content.user.userRegisterSuccess"));
    } else {
      helpers.notify(i18n.t("content.user.userUpdateSuccess"));
    }
    yield put(superAdminRegisterSuccess(_.get(result, "payload")));
  }
}
export function* handleSuperAdminRegisterError(error) {
  if (error.message === "super_admin_exists") {
    error.error = i18n.t(`apiResponseMessage.super_admin_exists`);
  }
  yield put(superAdminRegisterFailed(error));
}

export default function* watchAuth() {
  yield takeLeading(USER_REGISTER_REQUEST, safe(handleUserRegister));

  yield takeLeading(GET_USER_LIST_REQUEST, safe(handleGetUserList));

  yield takeLeading(USER_APPROVAL_REQUEST, safe(handleUserApproval));

  yield takeLeading(USER_DELETE_REQUEST, safe(handleUserDelete));

  yield takeLeading(USER_UPDATE_REQUEST, safe(handleUserUpdate));

  yield takeLeading(USER_INVITE_REQUEST, safe(handleUserInvite));

  yield takeLeading(ACCEPT_INVITE_REQUEST, safe(handleAcceptInvite));

  yield takeLeading(GET_USER_PROFILE_REQUEST, safe(handleGetUserProfile));

  yield takeLeading(USER_PROFILE_UPDATE_REQUEST, safe(handleUserProfileUpdate));

  yield takeLeading(GET_USER_SETTING_REQUEST, safe(handleGetUserSetting));

  yield takeLeading(USER_SETTING_UPDATE_REQUEST, safe(handleUserSettingUpdate));

  yield takeLeading(
    REGISTER_COUPON_SERVICE_REQUEST,
    safe(handleRegisterCouponService, handleRegisterCouponServiceError)
  );

  yield takeLeading(
    REGISTER_COUPON_SERVICE_V1_REQUEST,
    safe(handleRegisterCouponServiceV1, handleRegisterCouponServiceV1Error)
  );

  yield takeLeading(
    REGISTER_POINT_SERVICE_REQUEST,
    safe(handleRegisterPointService, handleRegisterPointServiceError)
  );

  yield takeLeading(
    REGISTER_PRODUCT_REQUEST,
    safe(handleRegisterProduct, handleRegisterProductError)
  );
  yield takeLeading(
    SUPER_ADMIN_REGISTER_REQUEST,
    safe(handleSuperAdminRegister, handleSuperAdminRegisterError)
  );
}
